// THIRD PARTY COOKIE NOTICE
// SCROLL LOCK
// <= IE9 FALLBACK
// GLOBAL VARIABLES
// PAGE LOADING INDICATOR
// PYRAMID JSON
// SITE NAME
// SEARCH REPORTS
// PYRAMID HTML
// MOBILE PYRAMID HTML
// PYRAMID SIDEBAR
// VIEW HIERARCHY (PYRAMID) LINKS
// HEADER NAVIGATION - PARENT LEVEL LINKS
// (MOBILE STYLE) HEADER DESKTOP MENU
	// HEADER NAVIGATION - TABS
// HEADER NAVIGATION - SETTINGS AND LOGOUT
// HEADER NAVIGATION - HIDE REPORT LINKS WITH NO REPORT URLS
// MOBILE MENU
// MOBILE MAIN CONTENT - ACCORDION NAV
// HEADER NAVIGATION - PROJECTS
// HEADER NAVIGATION - MOST USED
// HEADER NAVIGATION - VIDEOS
// MODAL FUNCTIONS
// BIND FUNCTIONS
// MINIMIZE TOOLBAR
// RESIZE OWL CONTROLS
// OWL CAROUSEL (MINIMIZE LIST)
// MISC FUNCTIONS
// COOKIE NOTICE
// LINK RULES
// OUTDATED BROWSER WARNING

$(document).ready(function() {

	window.onhashchange = function () {

		if (!location.hash && $('.modal__close-button').is(':visible')) {
			$('.modal__close-button').first().trigger('click');
		}
	}

	checkPageHashForModal();

	function checkPageHashForModal() {

		var pageHash = window.location.hash.substr(1);

		// regex of hash generated by Tableau
		// check if page hash contains /site/*/views/*
		var regexTableauModal = new RegExp('(\/site\/)(.*)(\/views\/)(.*)');
		var regexTableauModalResult = regexTableauModal.exec(pageHash);

		if (regexTableauModalResult != null) {
			switchToSiteBySiteName(regexTableauModalResult[2], pageHash);
		}

		// regex of hash generated by portal after opening a specific report modal
		// check if page hash contains site_*_*
		var regexPortalModal = new RegExp('(site_)([^_]*)(.*)');
		var regexPortalModalResult = regexPortalModal.exec(pageHash);

		if (regexPortalModalResult != null) {
			switchToSiteBySiteName(regexPortalModalResult[2], pageHash);
		}
	}

	function switchToSiteBySiteName(siteName, pageHash) {

		if (siteName != null && pageHash != null) {
			
			$.get('login.php', {
				'site': siteName,
				'ajax': 1
			}, function (data) {

				if (JSON.parse(data).success === 1) {
					window.location.replace('index.php#' + pageHash);
				}
			});
		}
	}

   // -------------------------------------------------------------------------------------------------------------------
   // -------------------------------------------------------------------------------------------------------------------
   // --- THIRD PARTY COOKIE NOTICE ---
   // -------------------------------------------------------------------------------------------------------------------
   // -------------------------------------------------------------------------------------------------------------------

   $('#third-party-cookie-iframe').attr('src', 'https://mindmup.github.io/3rdpartycookiecheck/start.html');

   var receiveMessage = function (evt) {

      if (evt.data === 'MM:3PCunsupported') {

         // third party cookies not supported

         // hide login wrapper
         $('.login-wrapper, #loading-spinner').hide();

         // show blocked cookie warning wrapper
         $('#blocked-cookie-warning-wrapper').css('display', 'block');

			}
			else if (evt.data === 'MM:3PCsupported') {

				// third party cookies supported

				// do nothing

      }

   };

   window.addEventListener('message', receiveMessage, false);

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- SCROLL LOCK ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	if(!$('body').hasClass('select-site-page')){
		// body scroll locked by default until pyramid is loaded
		$('body').addClass('no-scroll');
	}
	
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- <= IE9 FALLBACK ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// if outdated browser has not been detected (defined by class on html in head) run all code, else don't run code and show outdated browser warning (code for else statement is located at the bottom of this file)
	if(!$('html').hasClass('old-browser')) {

		// -------------------------------------------------------------------------------------------------------------------
		// -------------------------------------------------------------------------------------------------------------------
		// --- GLOBAL VARIABLES ---
		// -------------------------------------------------------------------------------------------------------------------
		// -------------------------------------------------------------------------------------------------------------------

		// define new timeline as global variable, required for various GSAP animations
		window.tl =  new TimelineLite();

		// -------------------------------------------------------------------------------------------------------------------
		// -------------------------------------------------------------------------------------------------------------------
		// --- PAGE LOADING INDICATOR ---
		// -------------------------------------------------------------------------------------------------------------------
		// -------------------------------------------------------------------------------------------------------------------
      
    // show loading spinner
		$('#loading-spinner').show();

		// function to remove page loading indicator to be called when pyramid data is ready
		function pyramidReady() {

			tl.to($('#loading-spinner'), .5, {delay: 0, opacity: 0});

			$('#page-loading-indicator').delay(500).fadeOut(500);

			$('body').removeClass('no-scroll');

		}
		
		// -------------------------------------------------------------------------------------------------------------------
		// -------------------------------------------------------------------------------------------------------------------
		// --- PYRAMID JSON ---
		// -------------------------------------------------------------------------------------------------------------------
		// -------------------------------------------------------------------------------------------------------------------

		// Steven Giggins' code start
		function isMessage(txt, msg) {
				return txt.substring(0, msg.length) === msg;
		}

		function d(text){
			console.log(text);
		}

		function buildSwitchSitesHeaderNav(sites) {
			
			for (var i = 0; i < sites.length; i++) {
				
				$('.nav-sidebar-switch-site-sub-list').append(

					'<li class="nav-sidebar-switch-site-sub-list__item">' +

						'<a href="' + sites[i].link + '">' +

							sites[i].name +

						'</a>' +

					'</li>'

				);
			}
		}

		function buildVideosHeaderNav(videos) {

			for (var i = 0; i < videos.length; i++) {

				$('.nav-sidebar-videos-sub-list').append(

					'<li class="nav-sidebar-videos-sub-list__item" data-id-sort-order="' + videos[i].order + '">' +

						'<a href="#" class="tutorial-video-link" data-tutorial-video-link="' + videos[i].videoLink + '">' +

							videos[i].videoName +

						'</a>' +

					'</li>'

				);

				$('.pyramid-accordions-content__videos .pyramid-accordions-content__list').append(

					'<li class="pyramid-accordions-content__list-item" data-id-sort-order="' + videos[i].order + '">' +

						'<a href="#" class="pyramid-accordions-content__list-link tutorial-video-link" data-tutorial-video-link="' + videos[i].videoLink + '">' +

							videos[i].videoName +

						'</a>' +

					'</li>'

				);

			}
		}

		function importVideosSheet() {
			viz.getWorkbook().activateSheetAsync('Videos').then(function () {

				sheet = viz.getWorkbook().getActiveSheet();
				options = {
					maxRows: 0, // Max rows to return. Use 0 to return all rows
					ignoreAliases: false,
					ignoreSelection: true,
					includeAllColumns: false
				};

				return sheet.getSummaryDataAsync(options).then(function (table) {

					var videoTutorials = [];

					// go through each row in the spreadsheet
					$.each(table.getData(), function (index, cells) {

						videoTutorials.push({
							'order': cells[1].value,
							'videoName': cells[3].value,
							'videoLink': cells[2].value
						});
					});

					buildVideosHeaderNav(videoTutorials);
				});
			});
		}

		function getSiteList() {

			$.getJSON("select_site.php?siteList=1", function (sites) {
				
				if (sites.length > 1) {
					buildSwitchSitesHeaderNav(sites);
				} else {
					$('#header-settings-list--switch-site').parent().hide();
				}
			});
		}

		// build pyramid				
		var token;
		var LOADED_INDICATOR = 'tableau.listening';
		var COMPLETE_INDICATOR = 'tableau.completed';

		$.getJSON("pyramid_json.php", function (data) {
				
				token = data.token;
				window.url = data.url;
				window.site = data.site;
				window.visibleSiteName = data.visibleSiteName;
				window.username = data.username;

				$(document).ready(function () {

					var vizLoaded = false;
					var vizDiv = document.getElementById("vizContainer"),
							options = {
									//hideTabs: true,
									onFirstInteractive: function () {
										window.auth = true;
										workbook = viz.getWorkbook();
										activeSheet = workbook.getActiveSheet();
										getSummaryData();
									}
							};
					window.addEventListener('message', function (msg) {
							
							if (isMessage(msg.data, LOADED_INDICATOR)) {

								vizLoaded = true;

							} else if (isMessage(msg.data, COMPLETE_INDICATOR)) {

								if (vizLoaded) {

									// do nothing

								} else {

										alert('Unable to load!');

								}
							}
					});

					function getSummaryData() {
							// sheet = viz.getWorkbook().getActiveSheet().getWorksheets().get("Storm Map Sheet");
							// If the active sheet is not a dashboard, then you can just enter:
							pyramidArray = {};
							pyramid = [];
							menus = [];
							window.tabMenuItems = [];
							favourites = [];
							recentlyViewed = [];
							mostViewed = [];

							try {
								viz.getWorkbook().activateSheetAsync('Most Viewed').then(function () {
										sheet = viz.getWorkbook().getActiveSheet();
										options = {
											maxRows: 0, // Max rows to return. Use 0 to return all rows
											ignoreAliases: false,
											ignoreSelection: true,
											includeAllColumns: false
										};
										sheet.getSummaryDataAsync(options).then(function (table) {
											
											$.each(table.getData(), function (index, value) {
                                                    
													sheet_headings = {};
													sheet_headings['username'] = value[2].value;
													
													if (sheet_headings['username'] == username) {

														sheet_headings['report'] = value[3].value;
														sheet_headings['site'] = value[0].value;
														sheet_headings['url'] = value[1].value;
														mostViewed.push(sheet_headings);
													}
                                            });
                                        });
                                        
										viz.getWorkbook().activateSheetAsync('Portal Control').then(function () {
											sheet = viz.getWorkbook().getActiveSheet();
											options = {
													maxRows: 0, // Max rows to return. Use 0 to return all rows
													ignoreAliases: false,
													ignoreSelection: true,
													includeAllColumns: false
											};
											sheet.getSummaryDataAsync(options).then(function (table) {
													
													//pyramid.hierarchies.hierarchy_level = '';
													//pyramid.hierarchies.blocks = [];

													// go through each row in the spreadsheet
													$.each(table.getData(), function (index, value) {
														sheet_headings = {};

														// Tableau Server v2020.2 format
														sheet_headings['division'] = value[0];
														sheet_headings['tab'] = value[1];
														sheet_headings['hierarchy_level'] = value[2];
														sheet_headings['block_id'] = value[3];
														sheet_headings['block_name'] = value[4];
														sheet_headings['evaluation'] = value[5];
														sheet_headings['project_id'] = value[6];
														sheet_headings['project_name'] = value[7];
														// Using report name as business name since business name in spreadsheet is null
														sheet_headings['business_name'] = value[9];
														sheet_headings['report'] = value[9];
														sheet_headings['video'] = value[10];
														sheet_headings['url'] = value[11];

														// Check if this hierarchy level has already been added
														pyramidIndex = pyramid.map(function (o) {
																return o.division;
														}).indexOf(sheet_headings['division'].value);
														if (pyramidIndex == -1) {
																a = {};
																a.division = sheet_headings['division'].value;
																a.hierarchies = [];
																pyramidIndex = pyramid.push(a);
																pyramidIndex--;
														}
														// build the item (business report)
														item = {};
														item.report = sheet_headings['report'].value;

														// check if it is a favourite or recently viewed
														favouriteIndex = favourites.map(function (o) {
																return o.report;
														}).indexOf(sheet_headings['report'].value);
														if (favouriteIndex != -1) {
																item.favourite_url = favourites[favouriteIndex].url;
														} else {
																item.favourite_url = '%null%';
														}
														mostViewedIndex = mostViewed.map(function (o) {
																return o.url;
														}).indexOf(sheet_headings['url'].value);
														if (mostViewedIndex != -1) {
																new_url = 'https://' + url + '/t/' + site;
																switched_url = mostViewed[mostViewedIndex].url.replace('https://' + url + '/#/site/' + site, new_url);
																switched_url = switched_url + ':embed=y&:showShareOptions=true&:display_count=no&:showVizHome=no';
																item.most_used_url = switched_url;
																mostViewed.splice(mostViewedIndex, 1);
														} else {
																item.most_used_url = '%null%';
														}

														// the URL has to be changed to include the trusted auth token
														//new_url = 'https://success.realworldretail.com/trusted/' + token + '/t/';
														new_url = 'https://' + url + '/t/' + site;
														//switched_url = sheet_headings['url'].value.replace('https://success.realworldretail.com/#/site/Skechers', new_url);
														if (sheet_headings['url'].value != "%null%") {
																switched_url = sheet_headings['url'].value.replace('https://' + url + '/#/site/' + site, new_url);
																item.url = switched_url + ':embed=y&:showShareOptions=true&:display_count=no&:showVizHome=no';
																// item.url = "https://www.realworldanalytics.com/";
														} else {
																item.url = '%null%';
														}
														//if(sheet_headings['division'].value != "%null%") {
														item.vimeo_id = sheet_headings['video'].value;
														item.project_id = sheet_headings['project_id'].value;
														item.project_name = sheet_headings['project_name'].value;
														item.business_name = sheet_headings['business_name'].value;
														// Check if this hierarchy level has already been added
														hierarchyIndex = pyramid[pyramidIndex].hierarchies.map(function (o) {
																return o.hierarchy_level;
														}).indexOf(sheet_headings['hierarchy_level'].value);
														
														if (sheet_headings['tab'].value !== '%null%') {

															// check if this menu already exists
															menuIndex = tabMenuItems.map(function (o) {
																return o.tab;
															}).indexOf(sheet_headings['tab'].value);

															// menu does not exist yet
															if (menuIndex == -1) {

																menuIndex = tabMenuItems.push({
																	'tab': sheet_headings['tab'].value,
																	'reports': []
																});
																menuIndex--;
															}

															arr_loc = tabMenuItems[menuIndex].reports.length;
															// item.tab_report_id = a.tab + '-' + arr_loc++;
															// chris - needed to open tab reports from minimize list
															item.tab_report_id = a.tab + '-' + arr_loc++ + '-0';
															tabMenuItems[menuIndex].reports.push(item);
														}

														if (sheet_headings['hierarchy_level'].value == '%null%') {
																menuIndex = menus.map(function (o) {
																	return o.tab;
																}).indexOf(sheet_headings['tab'].value);
																if (menuIndex == -1) {
																	a = {};
																	a.tab = sheet_headings['tab'].value;
																	a.reports = [];
																	menuIndex = menus.push(a);
																	menuIndex--;
																}
																arr_loc = menus[menuIndex].reports.length;
																// item.tab_report_id = a.tab + '-' + arr_loc++;
																// chris - needed to open tab reports from minimize list
																item.tab_report_id = a.tab + '-' + arr_loc++ + '-0';
																menus[menuIndex].reports.push(item);
														} else {
																// Create the hierarchy array if it doesn't exist
																if (hierarchyIndex == -1) {
																	a = {};
																	a.hierarchy_id = sheet_headings['block_id'].value.substring(0, 1);
																	a.hierarchy_level = sheet_headings['hierarchy_level'].value;
																	a.blocks = [];
																	hierarchyIndex = pyramid[pyramidIndex].hierarchies.push(a);
																	hierarchyIndex--;
																}
																// Check if this hierarchy level has already been added
																//blockIndex = $.inArray(sheet_headings['division'].value, pyramid[pyramidIndex].hierarchies[hierarchyIndex].blocks);
																blockIndex = pyramid[pyramidIndex].hierarchies[hierarchyIndex].blocks.map(function (o) {
																	return o.block_id;
																}).indexOf(sheet_headings['block_id'].value);
																// Create the block array if it doesn't exist
																if (blockIndex == -1) {
																	a = {};
																	a.block_id = sheet_headings['block_id'].value;
																		//a.evaluation_score = sheet_headings['evaluation'].value;
																	a.evaluation_score = Math.floor(Math.random()*(5-1+1)+1);
																	if (sheet_headings['block_name'].value != '%null%') {
																			a.block_name = sheet_headings['block_name'].value;
																	} else {
																			a.block_name = '';
																	}
																	a.reports = [];
																	blockIndex = pyramid[pyramidIndex].hierarchies[hierarchyIndex].blocks.push(a);
																	blockIndex--;
																}
																// Default to block name if business name is blank (mostly used for when there are multiple reports in a block
																if (sheet_headings['hierarchy_level'].value == "%null%") {
																	sheet_headings['hierarchy_level'].value = sheet_headings['tab'].value;
																}

																// Check if this report level has already been added
																/*businessNameIndex = $.inArray(sheet_headings['hierarchy_level'].value, pyramid.hierarchies[hierarchyIndex].blocks[blockIndex].business_names);
																businessNameIndex = pyramid.hierarchies[hierarchyIndex].blocks[blockIndex].business_names.map(function (o) {
																return o.business_name;
																}).indexOf(sheet_headings['hierarchy_level'].value);
																// Create the report array if it doesn't exist
																if (businessNameIndex == -1) {
																a = {};
																a.business_name = sheet_headings['hierarchy_level'].value;
																a.reports = [];
																businessNameIndex = pyramid.hierarchies[hierarchyIndex].blocks[blockIndex].business_names.push(a);
																businessNameIndex--;
																}*/

																// Add the actual report to the correct place
																if (sheet_headings['division'] != '%null%') {
																	pyramid[pyramidIndex].hierarchies[hierarchyIndex].blocks[blockIndex].reports.push(item);
																}
														}
                                                        //}
                                                        
                                                    });
                                                    
													// remove first element as it is null
													if (pyramid.length > 0 && pyramid[0].division === '%null%') {
														pyramid.shift();
													}

													if (menus.length > 0) {
														menus.splice(-1);
													}
                          
													// real data pyramid
													pyramidArray.pyramids = [];
													pyramidArray.pyramids.push(pyramid);
													pyramidArray.tabs = [];
													pyramidArray.tabs.push(menus);
													
													// define pyramid object in array as global variable to be used in various FUNCTIONS
													window.pyramids = pyramidArray.pyramids[0];
													
													// define tabs object in pyramids array as global variable to be used in various functions
													window.tabs = pyramidArray.tabs[0];
													// Steven Giggins' code end

													getSiteList();

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- SITE NAME ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	
	$('#portal-customer-name').text(window.visibleSiteName);

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- SEARCH REPORTS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	var searchArray = [];

	for (var i = 0; i < pyramids.length; i++) {

		var searchHierarchy = pyramids[i].hierarchies;

		// hierarchies
		for (var j = 0; j < searchHierarchy.length; j++) {

			var searchBlock = searchHierarchy[j].blocks;

			// blocks
			for (var k = 0; k < searchBlock.length; k++) {

				var searchBlockId = searchBlock[k].block_id;

				// reports
				var searchReport = searchBlock[k].reports;

				for (var l = 0; l < searchReport.length; l++) {

					var searchReportName;

					if (searchReport.length === 1) {

						// if single report
						searchReportName = searchReport[0].report;

						if (searchReportName != '%null%') {
							
							var reportData = {};

							reportData.reportNumber = '0';
							reportData.reportIdOrigin = i;
							reportData.reportId = searchBlockId;
							reportData.reportMinimizeId = searchBlockId + '-P' + i;
							reportData.report = searchReportName;

							searchArray.push(reportData);
							
						}

					}
					
					else {

						// if multi report
						searchReportName = searchReport[l].report;

						if (searchReportName != '%null%') {

							var reportData = {};

							reportData.reportNumber = '1';
							reportData.reportIdOrigin = i;
							reportData.reportId = searchBlockId + '-' + l;
							reportData.reportMinimizeId = searchBlockId + '-' + l + '-P' + i;
							reportData.report = searchReportName;

							searchArray.push(reportData);
						}

					}

				}

			}

		}

	}

	var report_data = searchArray;

	$.ajaxSetup({ cache: false });

	// mobile search
	$('.search-outer-mobile').on('keyup', '#mobile-search', function () {

		// empty result list on keyup function to append only relevant results
		$('.mobile-search-result-list').html('');

		var searchField = $('#mobile-search').val();
		var expression = new RegExp(searchField, "i");

		$.each(report_data, function (key, value) {

			// if search query found
			if (value.report.search(expression) != -1) {

				if (value.reportNumber === '0') {

					// single
					$('.mobile-search-result-list').append('<li class="mobile-search-result-list__item"><a href="#" class="mobile-search-result-list__link report-link report-link--single modal-hash-link" data-id-origin="' + value.reportIdOrigin + '" data-id="' + value.reportId + '" data-minimize-id="' + value.reportMinimizeId + '" data-report-name="' + value.report + '">' + value.report + '</a></li>');

				} else {

					// single child
					$('.mobile-search-result-list').append('<li class="mobile-search-result-list__item"><a href="#" class="mobile-search-result-list__link report-link report-link--single-child modal-hash-link" data-id-origin="' + value.reportIdOrigin + '" data-id="' + value.reportId + '" data-minimize-id="' + value.reportMinimizeId + '" data-report-name="' + value.report + '">' + value.report + '</a></li>');

				}

			}

		});

		// empty result list if input is empty
		if ($('#mobile-search').val() === '') {
			$('.mobile-search-result-list').html('');
		}

	});

	$('.search-outer-mobile').on('click', '.mobile-search-result-list .report-link--single', openModal);
	$('.search-outer-mobile').on('click', '.mobile-search-result-list .report-link--single', addToMinimizedList);
	$('.search-outer-mobile').on('click', '.mobile-search-result-list .report-link--single-child', openModal);
	$('.search-outer-mobile').on('click', '.mobile-search-result-list .report-link--single-child', openModalChildReport);
	$('.search-outer-mobile').on('click', '.mobile-search-result-list .report-link--single-child', addToMinimizedList);

	// desktop
	$('.header-nav-outer').on('keyup', '#search', function () {

		// empty result list on keyup function to append only relevant results
		$('.search-result-list').html('');

		var searchField = $('#search').val();
		var expression = new RegExp(searchField, "i");

		$.each(report_data, function (key, value) {
			
			// if search query found
			if (value.report.search(expression) != -1) {

				if (value.reportNumber === '0') {

					// single
					$('.search-result-list').append('<li class="search-result-list__item"><a href="#" class="search-result-list__link report-link report-link--single modal-hash-link" data-id-origin="' + value.reportIdOrigin + '" data-id="' + value.reportId + '" data-minimize-id="' + value.reportMinimizeId + '" data-report-name="' + value.report + '">' + value.report + '</a></li>');

				} else {

					// single child
					$('.search-result-list').append('<li class="search-result-list__item"><a href="#" class="search-result-list__link report-link report-link--single-child modal-hash-link" data-id-origin="' + value.reportIdOrigin + '" data-id="' + value.reportId + '" data-minimize-id="' + value.reportMinimizeId + '" data-report-name="' + value.report + '">' + value.report + '</a></li>');

				}

			}

		});

		// empty result list if input is empty
		if ($('#search').val() == '') {
			$('.search-result-list').html('');
		}

	});

	// on clicking search input field
	$('.search-wrapper').on('click', '#search', function (e) {

		// exclude search input from closing on clicking body
		$('.search-wrapper').on('click', '#search', function (e) {

			e.stopPropagation();

		});

		// close search results when clicking outside search input 
		$('body').on('click', function (e) {
			
			$('.search-result-list').html('');
			
			$('#search').val('');

		});

	});

	$('.search-wrapper').on('click', '.search-result-list__link', function (e) {

		// empty search input field
		$('#search').val('');

		// empty result list on clicking on a result
		$('.search-result-list').html('');

		e.preventDefault();

	});

	$('.search-wrapper').on('click', '.search-result-list .report-link--single', openModal);
	$('.search-wrapper').on('click', '.search-result-list .report-link--single', addToMinimizedList);
	$('.search-wrapper').on('click', '.search-result-list .report-link--single-child', openModal);
	$('.search-wrapper').on('click', '.search-result-list .report-link--single-child', openModalChildReport);
	$('.search-wrapper').on('click', '.search-result-list .report-link--single-child', addToMinimizedList);

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- PYRAMID HTML ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// for loop - pyramid
	for(var i = 0; i < pyramids.length; i++) {

		// get pyramid id
		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();

		// if pyramid not empty
		if(pyramidId !== '%null%') {

			// append pyramid html structure
			$('.pyramid-content').append(

				'<div class="pyramid-wrapper pyramid-wrapper-id--'+ pyramidId +'" data-pyramid-position="'+ i +'">' +

					'<div class="pyramid">' + 

						// pyramid hierarchy level A
						'<div class="pyramid__hierarchy-level pyramid__hierarchy-level--A">' + 
							'<div class="pyramid__hierarchy-level-row pyramid__hierarchy-level-row--A">' +
								'<a href="#" class="pyramid__block pyramid__block--A1 report-link" data-id="" data-id-origin="' + i + '"></a>' +
							'</div>' +
						'</div>' + 

						// pyramid hierarchy level B
						'<div class="pyramid__hierarchy-level pyramid__hierarchy-level--B">' +

							'<div class="pyramid__hierarchy-level-row pyramid__hierarchy-level-row--B1">' +
									'<a href="#" class="pyramid__block pyramid__block--B1 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--B2 report-link" data-id="" data-id-origin="' + i + '"></a>' +
							'</div>' +

							'<div class="pyramid__hierarchy-level-row pyramid__hierarchy-level-row--B2">' +
									'<a href="#" class="pyramid__block pyramid__block--B3 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--B4 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--B5 report-link" data-id="" data-id-origin="' + i + '"></a>' +
							'</div>' +

						'</div>' +

						// pyramid hierarchy level C
						'<div class="pyramid__hierarchy-level pyramid__hierarchy-level--C">' +

							'<div class="pyramid__hierarchy-level-row pyramid__hierarchy-level-row--C1">' +
									'<a href="#" class="pyramid__block pyramid__block--C1 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--C2 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--C3 report-link" data-id="" data-id-origin="' + i + '"></a>' +
							'</div>' +

							'<div class="pyramid__hierarchy-level-row pyramid__hierarchy-level-row--C2">' +
									'<a href="#" class="pyramid__block pyramid__block--C4 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--C5 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--C6 report-link" data-id="" data-id-origin="' + i + '"></a>' +
							'</div>' +

						'</div>' + 

						// pyramid hierarchy level D
						'<div class="pyramid__hierarchy-level pyramid__hierarchy-level--D">' +

							'<div class="pyramid__hierarchy-level-row pyramid__hierarchy-level-row--D1">' +
									'<a href="#" class="pyramid__block pyramid__block--D1 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--D2 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--D3 report-link" data-id="" data-id-origin="' + i + '"></a>' +
							'</div>' +

							'<div class="pyramid__hierarchy-level-row pyramid__hierarchy-level-row--D2">' +
									'<a href="#" class="pyramid__block pyramid__block--D4 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--D5 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--D6 report-link" data-id="" data-id-origin="' + i + '"></a>' +
									'<a href="#" class="pyramid__block pyramid__block--D7 report-link" data-id="" data-id-origin="' + i + '"></a>' +
							'</div>' +

						'</div>' + 

					'</div>' +

					// pyramid sidebar
					'<div class="pyramid-sidebar">' +
						'<div class="pyramid-sidebar__level pyramid-sidebar__level--A"><div class="pyramid-sidebar__level-inner"></div></div>' +
						'<div class="pyramid-sidebar__level pyramid-sidebar__level--B"><div class="pyramid-sidebar__level-inner"></div></div>' +
						'<div class="pyramid-sidebar__level pyramid-sidebar__level--C"><div class="pyramid-sidebar__level-inner"></div></div>' +
						'<div class="pyramid-sidebar__level pyramid-sidebar__level--D"><div class="pyramid-sidebar__level-inner"></div></div>' +
					'</div>' +

					// mobile pyramid
					'<div class="pyramid-mobile">' +
						'<ul class="pyramid-mobile-accordion"></ul>' +
					'</div>' +

				'<div>'

			);

			// add active class to first pyramid
			$('.pyramid-wrapper').eq(0).addClass('pyramid-wrapper--active');

			// append list of pyramid hierarchies links
			$('.view-hierarchy-list').append(

				'<li class="view-hierarchy-list__item">'+

					'<a href="#" class="view-hierarchy-list__link" data-hierarchy-name="'+ pyramids[i].division +'">'+

						pyramids[i].division +

					'</a>' +

				'</li>'

			);

			// only show first pyramid in array
			$('.pyramid-wrapper').eq(0).show();

			// set hierarchies json object
			var hierarchiesJsonObject = pyramids[i].hierarchies;

			// for loop - hierarchy
			for(var j = 0; j < hierarchiesJsonObject.length; j++) {

				// add name of hierarchy levels to pyramid sidebar
				$('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__hierarchy-level--' + hierarchiesJsonObject[j].hierarchy_id).attr('data-hierarchy-level', hierarchiesJsonObject[j].hierarchy_level);

				// get hierarchy level name
				var hierarchyLevelName = $('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__hierarchy-level--' + hierarchiesJsonObject[j].hierarchy_id).data('hierarchy-level');

				// set hierarchy name of each level to the matching pyramid sidebar level
				$('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid-sidebar__level--'+ hierarchiesJsonObject[j].hierarchy_id +' .pyramid-sidebar__level-inner').text(hierarchyLevelName);
				
				// set blocks json object
				var blocksJsonObject = hierarchiesJsonObject[j].blocks;

				// for loop - blocks
				for(var k = 0; k < blocksJsonObject.length; k++) {

					// set block id and add to matching pyramid block
					var blockId = blocksJsonObject[k].block_id;
					$('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__block--' + blockId).attr('data-id', blockId);

					// set block name and add to matching pyramid block
					var blockName = blocksJsonObject[k].block_name;
					$('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__block--' + blockId).html('<span>' + blockName + '</span>');

					// set block evaluation score and add to matching pyramid block
					var evaluationScore = blocksJsonObject[k].evaluation_score;
					$('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__block--' + blockId).attr('data-evaluation-score', evaluationScore);

					// set report json object
					var reportsJsonObject = blocksJsonObject[k].reports;

					// if block has one report and single class
					if(reportsJsonObject.length === 1) {

						// add single class to blocks with only one report
						$('.pyramid-wrapper-id--' + pyramidId + ' .pyramid__block--' + blockId).addClass('report-link--single modal-hash-link');

						// adds title attribute to selctor with the value of the single report name
						$('.pyramid-wrapper-id--' + pyramidId + ' .pyramid__block--' + blockId).attr('title', reportsJsonObject[0].report).data('report-name', reportsJsonObject[0].report);

						// set block minimize id
						var blockMinimizeId = blocksJsonObject[k].block_id;

						// set active pyramid position in pyramids object
						var pyrmaidActivePosition = $('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__block--' + blockId).closest('.pyramid-wrapper').data('pyramid-position');

						// set minimize id (used in minimize toolbar)
						blockMinimizeId = blockMinimizeId + '-P' + pyrmaidActivePosition;

						// add minimize id data attribute to pyramid blocks (used in minimize toolbar)
						$('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__block--' + blockId).attr('data-minimize-id', blockMinimizeId);

						// set single block report url variable
						var singleBlockReportUrl = reportsJsonObject[0].url;

						// if no url add no-report class to pyramid blocks
						if(singleBlockReportUrl === '%null%') {

							$('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__block--' + blockId).addClass('pyramid__block--no-report');
							
							// remove title attribute from empty pyramid blocks
							$('.pyramid-wrapper-id--' + pyramidId + ' .pyramid__block--' + blockId).attr('title', '');

						}

					}

					// else if block has more than one report and multiple class
					else {

						// add multiple class to blocks with multiple reports
						$('.pyramid-wrapper-id--'+ pyramidId + ' .pyramid__block--' + blockId).addClass('report-link--multiple');

						// add title attribute to selctor with the value of the number of child reports
						$('.pyramid-wrapper-id--' + pyramidId + ' .pyramid__block--' + blockId).attr('title', reportsJsonObject.length + ' Reports');

					}

				} // end for loop - blocks

			} // end for loop - hierarchies

		} // end if pyramid if not empty

	} // end for loop - pyramid array

	// for each pyramid block
	$('.pyramid__block').each(function() {

		// if pyrmaid block is empty and no report class
		if($(this).is(':empty')) {

			$(this).addClass('pyramid__block--no-report');

		}

	});

	// for each block without 'no report' class
	$('.pyramid__block:not(.pyramid__block--no-report)').each(function() {

		var pyramidBlockSpan = $(this).find('span');

		$(this).on('mouseenter', function(e) {

			// animate span up Xpx
			tl.to(pyramidBlockSpan, .25, {delay:0, y:'-2px'});

		});

		$(this).on('mouseleave', function(e) {

			// animate span back to original position
			tl.to(pyramidBlockSpan, .25, {delay:0, y:'0px'});

		});

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- MOBILE PYRAMID HTML ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// for loop - pyramid
	for(var i = 0; i < pyramids.length; i++) {

		// set pyramid id
		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();

		// if pyramid not empty
		if(pyramidId !== '%null%') {

			// 1. create array of project ids 
			var hierarchyIdObject = [];

			// get hierarchies object
			var hierarchiesJsonObject = pyramids[i].hierarchies;

			// for loop - hierarchy level
			for(var j = 0; j < hierarchiesJsonObject.length; j++) {

				// get blocks object
				var blocksJsonObject = hierarchiesJsonObject[j].blocks;

				// for loop - blocks
				for(var k = 0; k < blocksJsonObject.length; k++) {

					var blockId = blocksJsonObject[k].block_id;
					var blockIdFirstChar = blockId.charAt(0);

					// 2. only add id to array if doesn't already exist and is not empty
					if(blockId !== '') {

						var projectIdObject = {

							"id": blockIdFirstChar

						}

						// check if projectIdObject already exists in array
						objectLocation = false;
						
						for(var h = 0, len = hierarchyIdObject.length; h < len; h++) {

							if(hierarchyIdObject[h]['id'] === blockIdFirstChar) {
							
								objectLocation = h;
							
							}
						
						}

						// if block is not already in the array add it to list as either single or multiple report link
						if(objectLocation === false) {

							hierarchyIdObject.push(projectIdObject);

						}

					}

				} // end blocks

			} // end hierarchy

			// 3. create html list from array
			for(var o = 0; o < hierarchyIdObject.length; o++) {

				// 4. create list item html
				$('.pyramid-wrapper-id--'+ pyramidId +' .pyramid-mobile .pyramid-mobile-accordion').append(

					'<li class="pyramid-mobile-accordion__item pyramid-mobile-accordion__item--'+ hierarchyIdObject[o].id +'">' +

						'<a href="" class="pyramid-mobile-accordion__trigger pyramid-mobile-accordion__trigger--'+ hierarchyIdObject[o].id +'">' +

							$('.pyramid__hierarchy-level--'+ hierarchyIdObject[o].id).data('hierarchy-level') +

						'</a>' +

						'<ul class="pyramid-mobile-accordion__content pyramid-mobile-accordion__content--'+ hierarchyIdObject[o].id +'"></ul>' +

					'</li>'

				);

			} // end array

			// sort mobile pyramid in descending order from A to E
			var mobilePyramidOrder = $('.pyramid-wrapper-id--'+ pyramidId +' .pyramid-mobile .pyramid-mobile-accordion').find('.pyramid-mobile-accordion__item').sort(sortAsc);
			$('.pyramid-wrapper-id--'+ pyramidId +' .pyramid-mobile .pyramid-mobile-accordion').append(mobilePyramidOrder);

		} // end if null check

	} // end pyramid array

	// 6.
	for(var i = 0; i < pyramids.length; i++) {

		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();

		if(pyramidId !== '%null%') {
		
			// 1. create array of project ids 
			var hierarchyIdArray = [];

			var hierarchiesJsonObject = pyramids[i].hierarchies;

			// for loop - hierarchy level
			for(var j = 0; j < hierarchiesJsonObject.length; j++) {

				var blocksJsonObject = hierarchiesJsonObject[j].blocks;

				// for loop - blocks
				for(var k = 0; k < blocksJsonObject.length; k++) {

					var blockId = blocksJsonObject[k].block_id;

					// 2. only add id to array if doesn't already exist and is not empty
					if(blockId !== '') {

						window.hierarchyIdObject = {
							"id": blockId
						}

						// check if hierarchyIdObject already exists in array
						objectLocation = false;

						for(var h = 0, len = hierarchyIdArray.length; h < len; h++) {

							if(hierarchyIdArray[h]['id'] === blockId) {

								objectLocation = h;

							}

						}

						// if block is not already in the array add it to list as either single or multiple report link
						if(objectLocation === false) {

							hierarchyIdArray.push(hierarchyIdObject);

						}

					}

					// set mobile block id
					var mobilePyramidBlock = blocksJsonObject[k].block_id;

					// set evaluation score
					var evaluationScore = blocksJsonObject[k].evaluation_score;

					// only show block if it matches one of these block ids
					if(['A1','B1','B2','B3','B4','B5','C1','C2','C3','C4','C5','C6','D1','D2','D3','D4','D5','D6','D7'].indexOf(mobilePyramidBlock) !== -1) {

						// append each block name belonging to each hierarchy level in pyramid object
						$('.pyramid-wrapper-id--'+ pyramidId +' .pyramid-mobile-accordion__content--' + hierarchiesJsonObject[j].hierarchy_id).append(

							'<li class="pyramid-mobile-accordion__content-item">' +

								'<a href="#" class="pyramid-mobile-accordion__content-link" data-id-origin="' + i + '" data-id="' + blocksJsonObject[k].block_id + '" data-minimize-id="' + blocksJsonObject[k].block_id + '-P' + i + '" data-evaluation-score="' + evaluationScore + '">' +

									blocksJsonObject[k].block_name +

								'</a>' +

							'</li>'

						);

					}

					// 7. show sub reports list if block has them
					
					// get report objects
					var reportCount = blocksJsonObject[k].reports;

					// if has multiple reports
					if(reportCount.length > 1) {

						$('.pyramid-wrapper-id--' + pyramidId + ' .pyramid-mobile-accordion__content-link[data-id="'+ blocksJsonObject[k].block_id +'"]').addClass('report-link--multiple');

					}

					// else single report
					else {

						$('.pyramid-wrapper-id--' + pyramidId + ' .pyramid-mobile-accordion__content-link[data-id="' + blocksJsonObject[k].block_id + '"]').addClass('modal-hash-link report-link--single').attr('data-report-name', reportCount[0].report);

						var noReportUrl = reportCount[0].url;

						// if no report url
						if(noReportUrl === '%null%') {

							$('.pyramid-wrapper-id--' + pyramidId + ' .pyramid-mobile-accordion__content-link[data-id="'+ blocksJsonObject[k].block_id +'"]').closest('li').addClass('pyramid-mobile-accordion__content-item--no-report');

						}

					}					

				} // end blocks

			} // end hierarchy
		
		} // end if null check

	} // end pyramid array

	
	// for each pyramid mobile accordion item
	$('.pyramid-mobile-accordion__item').each(function() {

		// hide hierarchy from list if 'all' children have no report class
	  !$(this).find('ul:not(:has(li:not(.pyramid-mobile-accordion__content-item--no-report)))').parent().addClass('pyramid-mobile-accordion__content-item--no-report');

	});

	// accordion functionality
	$('.pyramid-mobile-accordion__trigger').click(function(e) {

		// open accordion content
		$(this).toggleClass('open').next('.pyramid-mobile-accordion__content').slideToggle(250);

		e.preventDefault();

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- PYRAMID SIDEBAR ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// pyramid sidebar set height function
	function pyramidSidebarHeight(e) {

		var pyramidActiveIndex = $('body').find('.pyramid-wrapper--active').data('pyramid-position');
		
		setTimeout(function() {

			$('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid-sidebar__level--D').height(($('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid__hierarchy-level-row--D1').outerHeight(true) + $('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid__hierarchy-level-row--D2').outerHeight(false)));

			$('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid-sidebar__level--C').height(($('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid__hierarchy-level-row--C1').outerHeight(true) + $('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid__hierarchy-level-row--C2').outerHeight(false)));

			$('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid-sidebar__level--B').height(($('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid__hierarchy-level-row--B1').outerHeight(true) + $('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid__hierarchy-level-row--B2').outerHeight(false)));

			$('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid-sidebar__level--A').height($('.pyramid-wrapper[data-pyramid-position="'+ pyramidActiveIndex +'"] .pyramid__hierarchy-level-row--A').outerHeight(false));

		}, 1);

	}

	pyramidSidebarHeight();

	// on window resize call resizeOwlControls function
	var resizePyramidSidebarHeight;

	$(window).resize(function() {

		clearTimeout(resizePyramidSidebarHeight);

		resizePyramidSidebarHeight = setTimeout(pyramidSidebarHeight, 100);

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- VIEW HIERARCHY (PYRAMID) LINKS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// add active class to first view hierarchy list item
	$('.view-hierarchy-list__link').eq(0).addClass('view-hierarchy-list__link--active');

	// on clicking pyrmaid hierarchy links (created above), only show selected pyramid
	$('.view-hierarchy-list').on('click', '.view-hierarchy-list__link', function(e) {

		// get hierarchy id
		var viewHierarchyListPyramidWrapperId = $(this).data('hierarchy-name').replace(/\s/g, "-").toLowerCase();;

		// remove active class from links
		$('.view-hierarchy-list__link').removeClass('view-hierarchy-list__link--active');

		// add active class to clicked link
		$(this).addClass('view-hierarchy-list__link--active');

		// hide all pyrmaids
		$('.pyramid-wrapper').hide();

		// show selected pyramid
		$('.pyramid-wrapper-id--'+ viewHierarchyListPyramidWrapperId).show();

		// remove pyramid active class
		$('.pyramid-wrapper').removeClass('pyramid-wrapper--active');

		// add pyramid active class to matching pyramid
		$('.pyramid-wrapper-id--'+ viewHierarchyListPyramidWrapperId).addClass('pyramid-wrapper--active');

		// hide all headers
		$('.header-list--level-0').hide();

		// show matching header to pyramid
		$('.header-list--level-0-'+ viewHierarchyListPyramidWrapperId).show();

		// hide all project lists
		$('.project-list').hide();

		// show matching header to pyramid
		$('.project-list--'+ viewHierarchyListPyramidWrapperId).show();

		// remove evaluated class from blocks
		$('.pyramid__block').removeClass('pyramid__block--evaluated');

		// remove not evaluated class from blocks
		$('.pyramid__block').removeClass('pyramid__block--not-evaluated');

		// remove evaluated classes from accordion content and triggers
		$('.pyramid-mobile-accordion__content-link, .pyramid-mobile-accordion__trigger').removeClass('pyramid__block--evaluated, pyramid__block--not-evaluated');

		// reset evaluated select to default option
		$('.eval-checkbox:checkbox').prop('checked', false);

		// hide all projects / favourites / recently used lists
		$('.nav-sidebar-projects-list, .nav-sidebar-most-used-list').hide();

		// show active projects / favourites / recently used lists
		$('.nav-sidebar-projects-list--'+ viewHierarchyListPyramidWrapperId +', .nav-sidebar-most-used-list--'+ viewHierarchyListPyramidWrapperId).show();

		// hide all projects / most used accordion in mobile content 
		$('.pyramid-accordions-content__projects, .pyramid-accordions-content__most-used').hide();

		// show active projects / most used accordion in mobile content 
		$('.pyramid-accordions-content__projects--'+ viewHierarchyListPyramidWrapperId + ', .pyramid-accordions-content__most-used--'+ viewHierarchyListPyramidWrapperId).show();

		// call pyramidSidebarHeight function
		pyramidSidebarHeight(e);

		e.preventDefault();

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- HEADER NAVIGATION - PARENT LEVEL LINKS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------	

	// for loop - pyramid
	for(var i = 0; i < pyramids.length; i++) {

		// get pyramid id
		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();

		// append header nav html structure
		$('.header-nav').append(

			'<ul class="header-list header-list--level-0 header-list--level-0-'+ pyramidId +'">' +

				'<li class="header-list__item header-list__item--level-0"><a href="#" id="header-list__link--projects" class="header-list__link header-list__link--no-link header-list__link-trigger">' +

					'projects' +

				'</li>' +

				'<li class="header-list__item header-list__item--level-0"><a href="#" id="header-list__link--most-used" class="header-list__link header-list__link--no-link header-list__link-trigger">' +

					'most used' +

				'</li>' +

				'<li class="header-list__item header-list__item--level-0"><a href="#" id="header-list__link--videos" class="header-list__link header-list__link--no-link header-list__link-trigger">' +

				'tutorials' +

				'</li>' +

			'</ul>'

		);

	} // end for loop - pyramid array

	// show first header nav
	$('.header-list').eq(0).show();

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- (MOBILE STYLE) HEADER DESKTOP MENU ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------			

	function openDesktopNav(e) {

		// remove active class from all links
		$('.header-list__link-trigger').removeClass('active');

		// add active class to clicked link
		$(this).addClass('active');

		// get portion of header link id name
		var headerListLinkId = $(this).attr('id').split('--').pop().trim();
		
		// hide all sidebar content items
		$('.nav__sidebar__content-item').hide();

		// show matching sidebar content item
		$('.nav__sidebar__content-item--'+ headerListLinkId).show();

		// add open class to sidebar content item first a
		$('.nav__sidebar__content-item--'+ headerListLinkId +' > a').addClass('open');

		// show sub list inside sidebar content item
		$('.nav__sidebar__content-item--'+ headerListLinkId).find('.nav-sidebar-sub-list').show();

		e.preventDefault();

	}

	$('.header-nav').on('click', '.header-list__link-trigger', openMobileNav);
	$('.header-nav').on('click', '.header-list__link-trigger', openDesktopNav);

	// on window resize call mobileDesktopMenuResize function
	var resizeDesktopMenu;

	$(window).resize(function() {

		clearTimeout(resizeDesktopMenu);

		resizeDesktopMenu = setTimeout(mobileDesktopMenuResize, 100);

	});

	function mobileDesktopMenuResize(e) {

		// if header trigger link has active class
		if($('.header-list__link-trigger').hasClass('active')) {

			// get name of active link
			var headerListLinkId = $('.header-list__link-trigger.active').attr('id').split('--').pop().trim();
			
			if(window.matchMedia('screen and (max-width: 992px)').matches) {

				// show all mobile nav items
				$('.nav__sidebar__content-item').show();

				// hide all mobile nav sub lists
				$('.nav__sidebar__content-item .nav-sidebar-sub-list').hide();

				// show active mobile nav item link
				$('.nav__sidebar__content-item--'+ headerListLinkId +' > a').show();

				// show active mobile nav sub list
				$('.nav__sidebar__content-item--'+ headerListLinkId +' .nav-sidebar-sub-list').show();

			} else {

				// hide all mobile nav items
				$('.nav__sidebar__content-item').hide();

				// show matching sidebar content item
				$('.nav__sidebar__content-item--'+ headerListLinkId).show();

				// add open class to sidebar content item first a
				$('.nav__sidebar__content-item--'+ headerListLinkId +' > a').addClass('open');

			}

		}

		else {

			// show all mobile nav items
			$('.nav__sidebar__content-item').show();				

			// remove open class from mobile nav link
			$('.nav__sidebar__content-item > a').removeClass('open');

		}

	}	

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- HEADER NAVIGATION - TABS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------	

	// console.log(tabMenuItems);

	// for loop - tabs
	for (var i = 0; i < tabMenuItems.length; i++) {

		// append tabs before first link li item
		$('.header-list--level-0 > .header-list__item:first-child').before(

			'<li class="header-list__item header-list__item--level-0">' + 

				'<a href="#" id="header-list__link--'+ tabMenuItems[i].tab +'" class="header-list__link header-list__link--no-link header-list__link-trigger">' + 

					tabMenuItems[i].tab +

				'</a>' + 

			'</li>'

		);

		// append each tab item to mobile nav
		$('.nav-sidebar-tabs-list-wrapper').append(

			'<ul class="nav-sidebar-tabs-list">' +

				'<li class="nav__sidebar__content-item nav__sidebar__content-item--'+ tabMenuItems[i].tab +' nav__sidebar__content--has-children">' + 

					'<a href="#" class="mobile-nav-tab-name">' + 

						tabMenuItems[i].tab + 

					'</a>' + 

					'<ul class="nav-sidebar-sub-list mobile-nav-tabs-list mobile-nav-tabs-list--'+ tabMenuItems[i].tab +'"></ul>' +

				'</li>' +

			'</ul>'

		);

		// get tab report object
		var tabReportsJsonObject = tabMenuItems[i].reports;

		// for loop - tab reports
		for(var j = 0; j < tabReportsJsonObject.length; j++) {

			// get tab report url
			var tabReportUrl = tabReportsJsonObject[j].url;

			// get tab project id
			// var tabProjectId = tabReportsJsonObject[j].project_id;

			// if project id not empty
			// if(tabProjectId !== '%null%') {

				// if tab report not empty
				if(tabReportUrl !== '%null%') {

					// <a href="#" class="modal-hash-link report-link report-link--single" data-id-origin="0" data-id="A1" data-minimize-id="A1-P0" data-report-name="1.05 Executive Dashboard">1.05 Executive Dashboard</a>

					// append tab report links to matching mobile tab nav
					$('.mobile-nav-tabs-list--' + tabMenuItems[i].tab).append(

						'<li class="">'+

							'<a href="#" class="mobile-nav-tab-link tab-link" data-id="' + tabReportsJsonObject[j].tab_report_id + '" data-minimize-id="'+ tabReportsJsonObject[j].tab_report_id + '">'+

								tabReportsJsonObject[j].business_name +

							'</a>' +

						'</li>'

					);

				}

			// }

		} // end for loop - tab reports

		var sidebarTabsListItemSortInnerText = $('.nav-sidebar-tabs-list .mobile-nav-tabs-list').find('li .mobile-nav-tab-link').sort(sortInnerText);
		$('.nav-sidebar-tabs-list .mobile-nav-tabs-list').append(sidebarTabsListItemSortInnerText);

	} // end for loop - tabs

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- HEADER NAVIGATION - SETTINGS AND LOGOUT ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------	

	$('.header-list--utilities').append(

		'<li class="header-list__item header-list__item--level-0">' +

			'<a href="#" id="header-settings-list--switch-site" class="header-list__link header-list__link-trigger header-list__link--no-link" title="switch site"></a>' +

		'</li>' +

		'<li class="header-list__item header-list__item--level-0">' +

			'<a href="https://'+url+'/#/site/'+site+'/user/local/'+username+'/settings" id="header-settings-list__settings" class="header-list__link header-list__link--no-link" title="settings"></a>' +

		'</li>' +

		'<li class="header-list__item header-list__item--level-0">' +

			'<a href="login.php?logout=logout" id="header-settings-list__logout" class="header-list__link header-list__link--no-link" title="logout"></a>' +

		'</li>'

	);

	$('.header-list--utilities').on('click', '.header-list__link-trigger', openMobileNav);
	$('.header-list--utilities').on('click', '.header-list__link-trigger', openDesktopNav);

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- HEADER NAVIGATION - HIDE REPORT LINKS WITH NO REPORT URLS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------	

	// for loop - pyramid
	for(var i = 0; i < pyramids.length; i++) {

		// for each header list item
		$('.header-list.hierarchy-list .header-list__item').each(function() {

			// add 'no report' class to parent element with hidden children
			!$(this).find('ul:not(:has(li:not(.header-list__item--no-report)))').parent().addClass('header-list__item--no-report');

		});

		// hide top level li item who children have no report class
		$('.header-list--level-2').each(function() {

			if($(this).hasClass('header-list__item--no-report')) {
				
				$(this).parent().addClass('header-list__item--no-report');

			}

		});

	} // end for loop - pyramid

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- MOBILE MENU ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// on clicking mobile menu button
	$('.nav__mobile__menu__button').on('click', openMobileNav);

	// call closeMobileNav function on clicking mobile menu close button
	$('.nav__sidebar__content__close').on('click', closeMobileNav);

	function openMobileNav(e) {

		// add open class to outer container
		$('.outer_container').addClass('isOpen_outer_container');

		// add open class to mobile menu
		$('.nav__sidebar__content').addClass('isOpen');
		
		// add active class to mobile menu button
		$(this).addClass('active');

		// delay adding ready class to mobile menu to set up clicking outside target to close functionality below
		setTimeout(function() {
			
			$('.nav__sidebar__content').addClass('close-on-touch-ready');

		}, 350);

		e.preventDefault();
	}

	// close mobile menu functionality
	function closeMobileNav(e) {

		// remove open class from outer container
		$('.outer_container').removeClass('isOpen_outer_container');

		// remove open class from mobile menu
		$('.nav__sidebar__content').removeClass('isOpen');

		// remove active class from mobile menu button
		$('.nav__mobile__menu__button').removeClass('active');

		// remove ready class from mobile menu 
		$('.nav__sidebar__content').removeClass('close-on-touch-ready');

		// close any open sub menus
		$('.nav-sidebar-sub-list, .nav-sidebar-projects-sub-list-projects').hide();

		// remove open class from any open sub menus
		$('.mobile-nav-tab-name, .nav__sidebar__content, .nav-sidebar-projects-sub-list__link').removeClass('open');

		// remove active class from header menu link
		$('.header-list__link-trigger').removeClass('active');

		// clear mobile search input and results
		$('.mobile-search-result-list').html('');
		$('#mobile-search').val('');

		e.preventDefault();

	}

	// append list items to mobile list
	$('.mobile-nav-settings-list').append(

		'<li><a href="https://'+url+'/#/site/'+site+'/user/local/'+username+'/settings" class="mobile-nav-tab-name">Settings</a></li>' +

		'<li><a href="login.php?logout=logout" class="mobile-nav-tab-name">Logout</a></li>'

	);

	// for loop - pyramid
	for(var i = 0; i < pyramids.length; i++) {

		// get pyramid id
		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();
		
		// append projects html structure
		$('.nav-sidebar-projects-list-wrapper').append(

			'<ul class="nav-sidebar-projects-list nav-sidebar-projects-list--'+ pyramidId +'">' +

				'<li class="nav__sidebar__content-item nav__sidebar__content-item--projects nav__sidebar__content--has-children">' + 

					'<a href="#" class="mobile-nav-tab-name">' +
					
						'Projects' +

					'</a>' + 

					'<ul class="nav-sidebar-sub-list nav-sidebar-projects-sub-list nav-sidebar-projects-sub-list--'+ pyramidId +'"></ul>' +

				'</li>' +

			'</ul>'

		);

		// append most used html structure
		$('.nav-sidebar-most-used-list-wrapper').append(

			'<ul class="nav-sidebar-most-used-list nav-sidebar-most-used-list--'+ pyramidId +'">' +

				'<li class="nav__sidebar__content-item nav__sidebar__content-item--most-used nav__sidebar__content--has-children">' + 

					'<a href="#" class="mobile-nav-tab-name">' +
					
						'most used' +

					'</a>' + 

					'<ul class="nav-sidebar-sub-list nav-sidebar-most-used-sub-list"></ul>' +

				'</li>' +

			'</ul>'

		);

	}

	// outside of loop as videos apply to all divisions
	// append videos html structure
	$('.nav-sidebar-videos-list-wrapper').append(

		'<ul class="nav-sidebar-videos-list">' +

			'<li class="nav__sidebar__content-item nav__sidebar__content-item--videos nav__sidebar__content--has-children">' +

				'<a href="#" class="mobile-nav-tab-name">' +

					'tutorials' +

				'</a>' +

				'<ul class="nav-sidebar-sub-list nav-sidebar-videos-sub-list"></ul>' +

			'</li>' +

		'</ul>'

	);

	// outside of loop as videos apply to all divisions
	// append videos html structure
	$('.nav-sidebar-switch-site-list-wrapper').append(

		'<ul class="nav-sidebar-switch-site-list">' +

			'<li class="nav__sidebar__content-item nav__sidebar__content-item--switch-site nav__sidebar__content--has-children">' +

				'<a href="#" class="mobile-nav-tab-name">' +

					'switch site' +

				'</a>' +

				'<ul class="nav-sidebar-sub-list nav-sidebar-switch-site-sub-list"></ul>' +

			'</li>' +

		'</ul>'

	);

	// on tapping window
	$(window).on('click touchend', function(e) {

		// if mobile nav has ready class
		if($('.nav__sidebar__content').hasClass('close-on-touch-ready')) {

			// detect if target clicked is outside nav__sidebar__content, if true call closeModal function
			if($('.nav__sidebar__content, .modal, .header-list__link-trigger, .video-modal').has(e.target).length == 0 && !$('.nav__sidebar__content, .modal, .header-list__link-trigger').is(e.target)) {

				// call closeMobileNav function
				closeMobileNav(e);

			}

		}

	});

	// show first mobile projects list
	$('.nav-sidebar-projects-list').eq(0).show();

	// show first mobile most used list
	$('.nav-sidebar-most-used-list').eq(0).show();

	// bind toggleMobileSubNav function to mobile-nav-tab-name click event
	$('.nav__sidebar__content--has-children').on('click', '.mobile-nav-tab-name', toggleMobileSubNav);

	// open mobile sub menu functionality
	function toggleMobileSubNav(e) {

		// toggle open class
		$(this).toggleClass('open');

		// open nav-sidebar-sub-list
		$(this).closest('li').find('.nav-sidebar-sub-list').slideToggle();
		
		if (!$(this).hasClass('open')) {
			// clear mobile search input and results
			$('.mobile-search-result-list').html('');
			$('#mobile-search').val('');
		}

		e.preventDefault();

	}

	// open mobile sub sub menu - projects
	$('.nav__sidebar__content').on('click', '.nav-sidebar-projects-sub-list__link', function(e) {

		$(this).toggleClass('open');

		$(this).closest('li').find('.nav-sidebar-projects-sub-list-projects').slideToggle();

		e.preventDefault();

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- MOBILE MAIN CONTENT - ACCORDION NAV ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	for(var i = 0; i < pyramids.length; i++) {

		// get pyramid id
		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();

		$('.pyramid-accordions-content').append(

			'<div class="pyramid-accordions-content__projects pyramid-accordions-content__projects--'+ pyramidId +'" data-pyramid-position="'+ i +'">' +

				'<a href="#" class="pyramid-accordions-content__heading pyramid-accordions-content__list-link-trigger">Projects</a>' +

				'<ul class="pyramid-accordions-content__list pyramid-accordions-content__list--'+ pyramidId +'"></ul>' +

			'</div>' +

			'<div class="pyramid-accordions-content__most-used pyramid-accordions-content__most-used--'+ pyramidId +'" data-pyramid-position="'+ i +'">' +

				'<a href="#" class="pyramid-accordions-content__heading pyramid-accordions-content__list-link-trigger">Most Used</a>' +

				'<ul class="pyramid-accordions-content__list pyramid-accordions-content__list--'+ pyramidId +'"></ul>' +

			'</div>'

		);

	}

	var tabMenuItemName;
	for (var i = 0; i < tabMenuItems.length; i++) {

		tabMenuItemName = tabMenuItems[i].tab;
		tabMenuItemName = tabMenuItemName.toLowerCase();

		$('.pyramid-accordions-content__tabs').append(

			'<div class="pyramid-accordions-content__' + tabMenuItemName + '">' +

				'<a href="#" class="pyramid-accordions-content__heading pyramid-accordions-content__list-link-trigger">' + tabMenuItems[i].tab + '</a>' +

				'<ul class="pyramid-accordions-content__list pyramid-accordions-content__list--' + tabMenuItemName + '"></ul>' +

			'</div>'

		);

		// get tab report object
		var tabReportsJsonObject = tabMenuItems[i].reports;

		// for loop - tab reports
		for (var j = 0; j < tabReportsJsonObject.length; j++) {

			// get tab report url
			var tabReportUrl = tabReportsJsonObject[j].url;

			// get tab project id
			// var tabProjectId = tabReportsJsonObject[j].project_id;

			// if project id not empty
			// if(tabProjectId !== '%null%') {

			// if tab report not empty
			if (tabReportUrl !== '%null%') {

				// append tab report links to matching mobile tab nav
				$('.pyramid-accordions-content__tabs .pyramid-accordions-content__list--' + tabMenuItemName).append(

					'<li class="pyramid-accordions-content__list-item">' +

						'<a href="#" class="pyramid-accordions-content__list-link tab-link" data-id="' + tabReportsJsonObject[j].tab_report_id + '" data-minimize-id="' + tabReportsJsonObject[j].tab_report_id + '-P0">' +

							tabReportsJsonObject[j].business_name +

						'</a>' +

					'</li>'

				);

			}

			// }

		} // end for loop - tab reports

		var mobileAccordionTabsListItemSortInnerText = $('.pyramid-accordions-content__tabs .pyramid-accordions-content__list--' + tabMenuItemName).find('li').sort(sortInnerText);
		$('.pyramid-accordions-content__tabs .pyramid-accordions-content__list--' + tabMenuItemName).append(mobileAccordionTabsListItemSortInnerText);

	}

	$('.pyramid-accordions-content').append(

		'<div class="pyramid-accordions-content__videos">' +

			'<a href="#" class="pyramid-accordions-content__heading pyramid-accordions-content__list-link-trigger">Tutorials</a>' +

			'<ul class="pyramid-accordions-content__list"></ul>' +

		'</div>'

	);

	// show first projects accordion in mobile content 
	$('.pyramid-accordions-content__projects').eq(0).show();

	// show first most used accordion in mobile content 
	$('.pyramid-accordions-content__most-used').eq(0).show();

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- HEADER NAVIGATION - PROJECTS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------	

	// create global array
	window.tabsProjectIdArray = [];

	// for loop - tabs
	for (var i = 0; i < tabMenuItems.length; i++) {

		var tabReportsJsonObject = tabMenuItems[i].reports;

		for(var j = 0; j < tabReportsJsonObject.length; j++) {

			// set project id and names as variables
			var b = tabReportsJsonObject[j].project_id;
			var c = tabReportsJsonObject[j].project_name;

			// 2. only add id to array if doesn't already exist and is not empty
			if(b !== '') {

				var projectIdObject = {
					"id": b,
					"name": c
				}

				// check if projectIdObject already exists in array
				objectLocation = false;

				for(var h = 0, len = tabsProjectIdArray.length; h < len; h++) {

					if( tabsProjectIdArray[h]['id'] === b) {

						objectLocation = h;

					}

				}

				// if block is not already in the array add it to list as either single or multiple report link
				if(objectLocation === false) {

					tabsProjectIdArray.push(projectIdObject);

				}

			}

		}

	} // end for loop - tabs

	// for loop to create list of available projects per pryamid
	for(var i = 0; i < pyramids.length; i++) {

		// merge pyramid and tab array - Steven
		var mergedPyramidTabArray  = tabsProjectIdArray.slice(0);

		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();

		if(pyramidId !== '%null%') {

			// 1. create array of project ids 
			window.projectIdArray = [];

			var hierarchiesJsonObject = pyramids[i].hierarchies;

			// for loop - hierarchy level
			for(var j = 0; j < hierarchiesJsonObject.length; j++) {

				var blocksJsonObject = hierarchiesJsonObject[j].blocks;

				// for loop - blocks
				for(var k = 0; k < blocksJsonObject.length; k++) {

					var reportsJsonObject = blocksJsonObject[k].reports;

					// for loop - reports
					for(var l = 0; l < reportsJsonObject.length; l++) {

						var b = reportsJsonObject[l].project_id;
						var c = reportsJsonObject[l].project_name;

						// 2. only add id to array if doesn't already exist and is not empty
						if(b !== '') {

							var projectIdObject = {
								"id": b,
								"name": c
							}

							// Steven Giggins' code start
							// merge pyramid and tab array
							objectLocation = mergedPyramidTabArray.map(function(o) {
								
								return o.id;

							}).indexOf(b);
							
							// merge pyramid and tab array
							if(objectLocation == -1) {

								projectIdArray.push(projectIdObject);

								mergedPyramidTabArray.push(projectIdObject);

							}
							// Steven Giggins' code end

						}

					} // end for loop - reports

				} // end blocks

			} // end hierarchy

			// 3. create html list from array
			for(var o = 0; o < mergedPyramidTabArray.length; o++) {
				
				// create projects list for mobile
				$('.nav-sidebar-projects-sub-list--'+ pyramidId).append(

					'<li class="nav-sidebar-projects-sub-list__item nav-sidebar-projects-sub-list__item--'+ mergedPyramidTabArray[o].id +'">' +

						'<a href="#" class="nav-sidebar-projects-sub-list__link">' +

							mergedPyramidTabArray[o].id + '. '+ mergedPyramidTabArray[o].name +

						'</a>' +

						'<ul class="nav-sidebar-projects-sub-list-projects nav-sidebar-projects-sub-list-projects--'+ mergedPyramidTabArray[o].id +'"></ul>' +

					'</li>'

				);

				// create projects list for home page content area
				$('.pyramid-accordions-content__projects .pyramid-accordions-content__list--'+ pyramidId).append(

					'<li class="pyramid-accordions-content__list-item pyramid-accordions-content__list-item--'+ mergedPyramidTabArray[o].id +'">' +

						'<a href="#" class="pyramid-accordions-content__list-link pyramid-accordions-content__list-link-trigger">' +

							mergedPyramidTabArray[o].id + '. '+ mergedPyramidTabArray[o].name +

						'</a>' +

						'<ul class="pyramid-accordions-content__sub-list pyramid-accordions-content__sub-list--'+ mergedPyramidTabArray[o].id +'"></ul>' +

					'</li>'

				);

			} // end array

			// 5. sort list by class
			var elem = $('.project-list--'+ pyramidId).find('.project-list__item').sort(sortAsc);
			$('.project-list--'+ pyramidId).append(elem);

			// 5.1
			var elem = $('.nav-sidebar-projects-sub-list--'+ pyramidId).find('.nav-sidebar-projects-sub-list__item').sort(sortAsc);
			$('.nav-sidebar-projects-sub-list--'+ pyramidId).append(elem);

			// 5.2
			var elem = $('.pyramid-accordions-content__list--'+ pyramidId).find('.pyramid-accordions-content__list-item').sort(sortAsc);
			$('.pyramid-accordions-content__list--'+ pyramidId).append(elem);

		} // end if null check

	} // end pyramid array

	// 6. for loop - pyramid array
	for(var i = 0; i < pyramids.length; i++) {

		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();

		// if pyrmaid not empty
		if(pyramidId !== '%null%') {
		
			// 1. create array of project ids 
			var hierarchiesJsonObject = pyramids[i].hierarchies;

			// for loop - hierarchy level
			for(var j = 0; j < hierarchiesJsonObject.length; j++) {

				var blocksJsonObject = hierarchiesJsonObject[j].blocks;

				// for loop - blocks
				for(var k = 0; k < blocksJsonObject.length; k++) {

					var objectBlockId = blocksJsonObject[k].block_id;

					var reportsJsonObject = blocksJsonObject[k].reports;
					
					// for loop - reports
					for(var l = 0; l < reportsJsonObject.length; l++) {

						var a;
						var b = reportsJsonObject[l].project_id;
						var c = reportsJsonObject[l].project_name;

						if(reportsJsonObject.length > 1) {

							a = '-' + l;

						} else {

							a = '';

						}

						// 2. only add id to array if doesn't already exist and is not empty
						if(b !== '' && b != '%null%') {

							// if id is in data range
							// uncomment the line below and it's closing "}" to only display reports that are within the visible pyramid block range
							// if (['A1','B1','B2','B3','B4','B5','C1','C2','C3','C4','C5','C6','D1','D2','D3','D4','D5','D6','D7'].indexOf(objectBlockId) !== -1) {

								// append projects html form home page content area
								$('.nav-sidebar-projects-sub-list--'+ pyramidId +' .nav-sidebar-projects-sub-list-projects--'+ b).append(

									'<li class="header-list__item project-list-sub__item project-list-sub__item--'+ objectBlockId +'">' +

									'<a href="#" class="header-list__link report-link modal-hash-link" data-id-origin="' + i + '" data-id="' + objectBlockId + a + '" data-minimize-id="' + objectBlockId + a + '-P' + i + '" data-report-name="' + reportsJsonObject[l].business_name + '">' +

											reportsJsonObject[l].business_name +

										'</a>' +

									'</li>'

								);

								// desktop
								var projectsDesktopSortInnerText = $('.nav-sidebar-projects-sub-list--'+ pyramidId +' .nav-sidebar-projects-sub-list-projects--'+ b).find('.project-list-sub__item').sort(sortInnerText);
								$('.nav-sidebar-projects-sub-list--'+ pyramidId +' .nav-sidebar-projects-sub-list-projects--'+ b).append(projectsDesktopSortInnerText);

								// append mobile projects html
								$('.pyramid-accordions-content__projects .pyramid-accordions-content__list--'+ pyramidId +' .pyramid-accordions-content__sub-list--'+ b).append(

									'<li class="pyramid-accordions-content__sub-list-item pyramid-accordions-content__sub-list-item--'+ objectBlockId +'">' +

										'<a href="#" class="header-list__link modal-hash-link report-link" data-id-origin="' + i + '" data-id="' + objectBlockId + a + '" data-minimize-id="' + objectBlockId + a + '-P' + i + '" data-report-name="' + reportsJsonObject[l].business_name + '">' +

											reportsJsonObject[l].business_name +

										'</a>' +

									'</li>'

								);

								// mobile
								var projectsMobileSortInnerText = $('.pyramid-accordions-content__projects .pyramid-accordions-content__list--'+ pyramidId +' .pyramid-accordions-content__sub-list--'+ b).find('.pyramid-accordions-content__sub-list-item').sort(sortInnerText);
								$('.pyramid-accordions-content__projects .pyramid-accordions-content__list--'+ pyramidId +' .pyramid-accordions-content__sub-list--'+ b).append(projectsMobileSortInnerText);

							// } // end if in data range check

							// hide links with no report url
							var noReportUrl = reportsJsonObject[l].url;

							if(noReportUrl === '%null%') {

								$('.project-list--'+ pyramidId +' .header-list__link[data-id="'+ objectBlockId + a +'"]').closest('li').addClass('header-list__item--no-report');

								$('.nav-sidebar-projects-sub-list-projects .header-list__link[data-id="'+ objectBlockId + a +'"]').closest('li').addClass('header-list__item--no-report');

								$('.pyramid-accordions-content__projects .pyramid-accordions-content__list .header-list__link[data-id="'+ objectBlockId + a +'"]').closest('li').addClass('header-list__item--no-report');

							}

						}

					} // end for loop - reports

					// Steven Giggins' code start
					/*
					getBlockJsonObject contains the blocks for each pyramid in an array
					If the first pyramid does not have that block ID then use 0.
					This will only work if there are two pyramids. We'll need to make more changes if there are more than 2.
					*/
					/*
					// get object with block id objectBlockId
					var getBlockJsonObject = getObjects(pyramids, 'block_id', objectBlockId);
					
					if(getBlockJsonObject[i]) {
						
						pyramidToUse = i;

					} else {

						pyramidToUse = 0;

					}
					
					var reportObject = getBlockJsonObject[i].reports;
					var reportObject = getBlockJsonObject[pyramidToUse].reports;

					assign appropriate class depending on number of reports
					if(reportObject.length > 1) {

						console.log(reportObject);

					}
					else {

						console.log(reportObject);

					} */
					// Steven Giggins' code end

				} // end for loop - blocks

			} // end for loop - hierarchy

		} // end if null check

	} // end for loop - pyramid array

	// here
	// loop through tab array and append any reports with project id to projects if matching ul
	for(var i = 0; i < tabs.length; i++) {

		var tabReportsJsonObject = tabs[i].reports;

		// for loop - tab reports
		for(var j = 0; j < tabReportsJsonObject.length; j++) {

			// get tab report url
			var tabReportUrl = tabReportsJsonObject[j].url;

			var a;
			var b = tabReportsJsonObject[j].project_id;
			var c = tabReportsJsonObject[j].project_name;

			if(tabReportsJsonObject.length > 1) {

				a = '-' + l;

			} else {

				a = '';

			}

			// 2. only add id to array if doesn't already exist and is not empty
			if(b !== '%null%') {

				// if tab report not empty
				if(tabReportUrl !== '%null%') {

					// append projects to sub list
					$('.project-list .project-list-sub--'+ b).append(

						'<li class="header-list__item project-list-sub__item project-list-sub__item--'+ objectBlockId +'">' +

							'<a href="#" class="header-list__link tab-link" data-id="'+ tabReportsJsonObject[j].tab_report_id +'" data-minimize-id="'+ tabReportsJsonObject[j].tab_report_id +'-P0">'+

								tabReportsJsonObject[j].business_name +

							'</a>' +

						'</li>'

					);

					// append projects to mobile sub list
					$('.nav-sidebar-projects-sub-list-projects--'+ b).append(

						'<li class="header-list__item project-list-sub__item project-list-sub__item--'+ objectBlockId +'">' +

							'<a href="#" class="header-list__link tab-link" data-id="'+ tabReportsJsonObject[j].tab_report_id +'" data-minimize-id="'+ tabReportsJsonObject[j].tab_report_id +'-P0">'+

								tabReportsJsonObject[j].business_name +

							'</a>' +

						'</li>'

					);

					// append projects to main content area sub list
					$('.pyramid-accordions-content__projects .pyramid-accordions-content__sub-list--'+ b).append(

						'<li class="pyramid-accordions-content__sub-list-item pyramid-accordions-content__sub-list-item--'+ objectBlockId +'">' +

							'<a href="#" class="header-list__link tab-link" data-id="'+ tabReportsJsonObject[j].tab_report_id +'" data-minimize-id="'+ tabReportsJsonObject[j].tab_report_id +'-P0">'+

								tabReportsJsonObject[j].business_name +

							'</a>' +

						'</li>'

					);

				}

			}

		}

	}

	$('.project-list-sub__item').each(function() {

			var reportLinkId = $(this).find('.report-link').data('id');

			if(reportLinkId.length > 2) {

				$(this).find('.report-link').addClass('report-link--single-child report-link--single open-dropdown-single-child-report');

			}

			else {

				$(this).find('.report-link').addClass('report-link--single');

			}

	});

	$('.pyramid-accordions-content__sub-list-item').each(function() {

			var reportLinkId = $(this).find('.report-link').data('id');

			if(reportLinkId.length > 2) {

				$(this).find('.report-link').addClass('report-link--single-child open-dropdown-single-child-report');

			}

			else {

				$(this).find('.report-link').addClass('report-link--single');

			}

	});

	// for each project list item in header
	$('.project-list__item').each(function() {

		// hide 'project' parent from list if 'all' children have no report class
		!$(this).find('ul:not(:has(li:not(.project-list-sub__item.header-list__item--no-report)))').parent().addClass('header-list__item--no-report');

	});

	// for project list
	$('.project-list-sub').each(function() {

		// if this list has no children
		if($(this).find('li').length === 0) {

			// hide parent list element
			$(this).closest('.project-list__item').hide();

		}

	});

	// for project list in main content area
	$('.pyramid-accordions-content__list-item').each(function() {

		// hide 'project' parent from list if 'all' children have no report class
		!$(this).find('ul:not(:has(li:not(.header-list__item--no-report)))').parent().addClass('header-list__item--no-report');

	});

	// hide 'no report' in mobile nav
	$('.nav-sidebar-projects-sub-list__item').each(function() {

		// hide 'project' parent from list if 'all' children have no report class
		!$(this).find('ul:not(:has(li:not(.header-list__item--no-report)))').closest('.nav-sidebar-projects-sub-list__item').addClass('header-list__item--no-report');

	});

	// show first project list
	$('.project-list').eq(0).show();

	$('.pyramid-accordions-content__list-link.pyramid-accordions-content__list-link-trigger').click(function(e) {

			// open accordion content
			$(this).toggleClass('open').next('.pyramid-accordions-content__sub-list').slideToggle(250);

		e.preventDefault();

	});

	$('.pyramid-accordions-content__heading.pyramid-accordions-content__list-link-trigger').click(function(e) {

			// open accordion content
			$(this).toggleClass('open').next('.pyramid-accordions-content__list').slideToggle(250);

		e.preventDefault();

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- HEADER NAVIGATION - MOST USED ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// for loop to create list of available favourites per pryamid
	for(var i = 0; i < pyramids.length; i++) {

		var pyramidId = pyramids[i].division.replace(/\s/g, "-").toLowerCase();

		// if pyrmaid empty
		if(pyramidId !== '%null%') {
		
			// 1. create array of favourite ids 
			var favouriteIdArray = [];

			var hierarchiesJsonObject = pyramids[i].hierarchies;

			// for loop - hierarchy level
			for(var j = 0; j < hierarchiesJsonObject.length; j++) {

				var blocksJsonObject = hierarchiesJsonObject[j].blocks;

				// for loop - blocks
				for(var k = 0; k < blocksJsonObject.length; k++) {

					var reportsJsonObject = blocksJsonObject[k].reports;

					var reportsJsonBlockId = blocksJsonObject[k].block_id;

					// for loop - reports
					for(var l = 0; l < reportsJsonObject.length; l++) {

						var reportsJsonMostUsedUrl = reportsJsonObject[l].most_used_url,
							reportsJsonUrl = reportsJsonObject[l].url;

						var a;

						// if more than one report set variable to include poistion (l) to be added to data id and data minimize id
						if(reportsJsonObject.length > 1) {

							a = '-' + l;

						} else {

							a = '';

						}

						// if most used url is not null
						if(reportsJsonMostUsedUrl !== '%null%') {

							// if report url is not null
							if(reportsJsonUrl !== '%null%') {	

								// if id is in data range
								// uncomment the line below and it's closing "}" to only display reports that are within the visible pyramid block range
								// if(['A1','B1','B2','B3','B4','B5','C1','C2','C3','C4','C5','C6','D1','D2','D3','D4','D5','D6','D7'].indexOf(reportsJsonBlockId) !== -1) {

									// append list of reports - mobile
									$('.nav-sidebar-most-used-list--'+ pyramidId +' .nav-sidebar-most-used-sub-list').append(

										'<li class="nav-sidebar-most-used-sub-list__item nav-sidebar-most-used-sub-list__item--'+ reportsJsonBlockId +'">' +

										'<a href="#" class="nav-sidebar-most-used-sub-list__link header-list__link modal-hash-link report-link" data-id-origin="' + i + '" data-id="' + reportsJsonBlockId + a + '" data-minimize-id="' + reportsJsonBlockId + a + '-P' + i + '" data-report-name="' + reportsJsonObject[l].business_name + '">' +

												reportsJsonObject[l].business_name +

											'</a>' +

										'</li>'

									);

									// desktop
									var mostUsedDesktopSortInnerText = $('.nav-sidebar-most-used-list--'+ pyramidId +' .nav-sidebar-most-used-sub-list').find('.nav-sidebar-most-used-sub-list__item').sort(sortInnerText);
									$('.nav-sidebar-most-used-list--'+ pyramidId +' .nav-sidebar-most-used-sub-list').append(mostUsedDesktopSortInnerText);

									// create projects list for home page content area
									$('.pyramid-accordions-content__most-used .pyramid-accordions-content__list--'+ pyramidId).append(

										'<li class="pyramid-accordions-content__list-item pyramid-accordions-content__list-item--'+ reportsJsonBlockId +'">' +

											'<a href="#" class="pyramid-accordions-content__list-link modal-hash-link report-link" data-id-origin="' + i + '" data-id="' + reportsJsonBlockId + a + '" data-minimize-id="' + reportsJsonBlockId + a + '-P' + i + '" data-report-name="' + reportsJsonObject[l].business_name + '">' +

												reportsJsonObject[l].business_name +

											'</a>' +

										'</li>'

									);

									// mobile
									var mostUsedMobileSortInnerText = $('.pyramid-accordions-content__most-used .pyramid-accordions-content__list--'+ pyramidId).find('.pyramid-accordions-content__list-item').sort(sortInnerText);
									$('.pyramid-accordions-content__most-used .pyramid-accordions-content__list--'+ pyramidId).append(mostUsedMobileSortInnerText);
									
								// } // end if in data range check

							}

						}

						// add appropriate class to link if single or child report link
						if(reportsJsonObject.length > 1) {

							$('.most-used-list__item--'+ reportsJsonBlockId +' a').addClass('report-link--single-child');

						} else {

							$('.most-used-list__item--'+ reportsJsonBlockId +' a').addClass('report-link--single');

						}
						
					} // end for loop - reports

				} // end blocks

			} // end hierarchy

		} // end if null check

	} // end for loop - pyramid array

	// for loop - tabs
	for(var i = 0; i < tabs.length; i++) {

		var tabReportsJsonObject = tabs[i].reports;

		for(var j = 0; j < tabReportsJsonObject.length; j++) {

			var tabReportMostUsedUrl = tabReportsJsonObject[j].most_used_url,
					tabReportReportUrl = tabReportsJsonObject[j].url;

			// if most used url is not null
			if(tabReportMostUsedUrl !== '%null%') {

				// if report url is not null
				if(tabReportReportUrl !== '%null%') {

					// append list of reports - mobile
					$('.nav-sidebar-most-used-sub-list').append(

						'<li class="nav-sidebar-most-used-sub-list__item nav-sidebar-most-used-sub-list__item--'+ tabReportsJsonObject[j].tab_report_id +'">' +

							'<a href="#" class="mobile-nav-tab-link tab-link" data-id="'+ tabReportsJsonObject[j].tab_report_id +'" data-minimize-id="'+ tabReportsJsonObject[j].tab_report_id +'-P0">' +

								tabReportsJsonObject[j].business_name +

							'</a>' +

						'</li>'

					);

					// append projects to main content area sub list
					$('.pyramid-accordions-content__most-used .pyramid-accordions-content__list').append(

						'<li class="pyramid-accordions-content__list-item pyramid-accordions-content__list-item--'+ tabReportsJsonObject[j].tab_report_id +'">' +

							'<a href="#" class="pyramid-accordions-content__list-link tab-link" data-id="'+ tabReportsJsonObject[j].tab_report_id +'" data-minimize-id="'+ tabReportsJsonObject[j].tab_report_id +'-P0">' +

								tabReportsJsonObject[j].business_name +

							'</a>' +

						'</li>'

					);

				}

			}

		}

	}

	$('.nav-sidebar-most-used-sub-list__item').each(function() {

			var reportLinkId = $(this).find('.report-link').data('id');

			if(reportLinkId.length > 2) {

				$(this).find('.report-link').addClass('report-link--single-child report-link--single open-dropdown-single-child-report');

			}

			else {

				$(this).find('.report-link').addClass('report-link--single');

			}

	});

	$('.pyramid-accordions-content__most-used .pyramid-accordions-content__list-item:not(.header-list__item--no-report) .pyramid-accordions-content__list-link').each(function() {

			var reportLinkId = $(this).data('id');

			if(reportLinkId.length > 2) {

				$(this).addClass('report-link--single-child open-dropdown-single-child-report');

			}

			else {

				$(this).addClass('report-link--single');

			}

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- HEADER NAVIGATION - VIDEOS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	var videosSortOrder = $('.nav-sidebar-videos-list-wrapper').find('.nav-sidebar-videos-sub-list__item').sort(sortDataIdOrder);
	$('.nav-sidebar-videos-sub-list').append(videosSortOrder);

	var videosSortOrderAccordion = $('.pyramid-accordions-content').find('.pyramid-accordions-content__videos .pyramid-accordions-content__list-item').sort(sortDataIdOrder);
	$('.pyramid-accordions-content__videos .pyramid-accordions-content__list').append(videosSortOrderAccordion);

	//
	$('.nav-sidebar-videos-list-wrapper, .pyramid-accordions-content').on('click', '.tutorial-video-link', function (e) {

		var tutorialVideoId = $(this).data('tutorial-video-link');

		if (window.matchMedia('screen and (max-width: 768px)').matches) {

			// open vimeo in new tab
			var tutorialVideoLink = 'https://vimeo.com/' + tutorialVideoId;
			window.open(tutorialVideoLink, '_blank');

		} else {

			// add video id to iframe src attribute
			$('.video-modal__inner-content iframe').attr('src', 'https://player.vimeo.com/video/' + tutorialVideoId);

			openVideoModal(e);

		}

		e.preventDefault();
		e.stopPropagation();
	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- MODAL FUNCTIONS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	openIframeFromHash();

	// listen for hash change to call function as user navigates through browser history
	// http://success.realworldanalytics.com/test/login.php#/site/AutoDemo/views/1_00DailyActivity/Overall
	window.addEventListener("hashchange", openIframeFromHash);
	
	function openIframeFromHash(e) {

		var pageUrl = $(location).attr('href');

		if (pageUrl.indexOf('#/site') >= 0) {
			
			// check if page hash contains /site/*/views/*
			var regex = new RegExp('(\/site\/)(.*)(\/views\/)(.*)');
			var pageHash = window.location.hash.substr(1);
			var regexResult = regex.exec(pageHash);

			if (regexResult != null) {

				// get URL for the report and open it in an iframe
				$.get('index.php', {
					'iframeUri': pageHash
				}, function (data) {

					var iframeUrl = JSON.parse(data);

					var urlReportName = pageHash.substring(pageHash.indexOf('views/') + 6);
					urlReportName = urlReportName.substring(0, urlReportName.lastIndexOf('/'));
					urlReportName = urlReportName.replace('_', '.');
					urlReportName = urlReportName.replace(/([A-Z])/g, ' $1').trim()

					openSubscriptionModal(e, iframeUrl, urlReportName);

				});

			}

		} else {

			// remove body scrolling lock after timeout
			setTimeout(function () {

				$('body').removeClass('no-scroll');

			}, 1000);

			// fadeOut modal after delay
			$('.subscription-link-modal').delay(550).fadeOut(350);

			// reset scale and opacity
			tl.to($('.subscription-link-modal__inner'), .5, { delay: 0, opacity: 0, scaleX: .85, scaleY: .85 });

			//
			$('.subscription-link-modal__inner-title').text('');

			//
			$('#subscription-link-modal-iframe-wrapper iframe').remove();

			// leave commented out, prevents site from loading
			// e.preventDefault();

			// not a parent modal
			if (pageUrl.indexOf('#reports_') === 0) {
				closeModalHash(e);
			}
			
		}

	}

	function openModalSetUrlHash() {

		var hashTitle = $(this).data('report-name').replace('.', '--').split(' ').join('-');

		var hashBlockId = $(this).data('id');

		var hashReportOrigin = $(this).data('id-origin');

		var hashDivision = pyramids[hashReportOrigin].division;

		hashDivision = hashDivision.split(' ').join('-');

		// e.g. #site_AutoDemo_0_A1_1--05-Executive-Dashboard_Sales
		var pageHash = '#site_' + window.site + '_' + hashReportOrigin + '_' + hashBlockId + '_' + hashTitle + '_' + hashDivision;

		if (history.pushState) {
			history.pushState(null, null, pageHash);
		} else {
			location.hash = pageHash;
		}
	}

	/*
		.search-wrapper - desktop search result links
		.pyramid-content - desktop pyramid block links
		.nav__sidebar__content - desktop sidebar links
		.minimize-list - minimize list links
		.modal__inner-content-multi-report-list - single child reports in modal
		.pyramid-accordions-content - mobile projects/most used links
	*/
	$('.search-wrapper, .pyramid-content, .nav__sidebar__content, .minimize-list, .modal__inner-content-multi-report-list, .pyramid-accordions-content').on('touchend click', '.modal-hash-link', openModalSetUrlHash);

	function closeModalHash(e) {
		location.hash = '';
		history.replaceState({}, document.title, location.href.replace('#', ''));
	}

	// close modal tasks depending on whether opening single || child || tab || minimized list report
	function openModal(e) {

		if(window.matchMedia('screen and (min-width: 768px)').matches) {

			$('.view-hierarchy-list-container, .pyramid-content, .pyramid-accordions-content, .footer-wrapper, #cookie-note').css('opacity', 0);

		}

		// if opening modal from a tab link (see tab object in pyramid array)
		if($(this).hasClass('tab-link')) {

			// get tab id
			var getBlockJsonObject = getObjects(tabMenuItems, 'tab_report_id', $(this).data('id'));

			// set blockReportIndex to get related report url
			window.blockReportIndex = getBlockJsonObject[0];

			// call openSingleReportModal function
			openSingleReportModal(e);

		}

		// if opening modal from a single report link
		if($(this).hasClass('report-link--single')) {

			// if link does have a report url
			if(!$(this).hasClass('pyramid__block--no-report') && !$(this).hasClass('open-dropdown-single-child-report')) {

				var pyramidActiveIndex;

				if ($(this).hasClass('most-used-list__link') || $(this).hasClass('nav-sidebar-most-used-sub-list__link') || $(this).hasClass('search-result-list__link') || $(this).hasClass('mobile-search-result-list__link')) {

					pyramidActiveIndex = $(this).data('id-origin');

				} else {

					// get active pryramid index in pyramids array
					pyramidActiveIndex = $('body').find('.pyramid-wrapper--active').data('pyramid-position');

				}

				// returns an array of matching objects
				var getBlockJsonObject = getObjects(pyramids, 'block_id', $(this).data('id'));

				// set block object data values
				var blockReports;

				// if more than one block show report at block at approriate index
				if(getBlockJsonObject.length === 1) {

					blockReports = getBlockJsonObject[0].reports;

				} else {

					blockReports = getBlockJsonObject[pyramidActiveIndex].reports;	

				}

				// set block report index as global variable
				window.blockReportIndex = blockReports[0];

				// call openSingleReportModal function
				openSingleReportModal(e);

			} else {

				// block has no report url

				// do nothing

			}

		}

		// if link has multiple reports
		if($(this).hasClass('report-link--multiple')) {

			// add multiple class to modal close button
			$('.modal__close-button').addClass('modal__close-button--multiple');

			// remove modal inner single report class
			$('.modal__inner').removeClass('modal__inner--single-report');

			// add modal inner multiple report class
			$('.modal__inner').addClass('modal__inner--multiple-report');

			// add block title to modal heading
			$('.modal__inner-title').text($(this).text());

			// show multi report list
			$('.modal__inner-content-multi-report-list').show();

			var pyramidActiveIndex = $('body').find('.pyramid-wrapper--active').data('pyramid-position');

			// returns an array of matching objects
			var getBlockJsonObject = getObjects(pyramids, 'block_id', $(this).data('id'));

			// set block object data values
			var blockReports;

			// if more than one block show report at block at approriate index
			if(getBlockJsonObject.length === 1) {

				blockReports = getBlockJsonObject[0].reports;

			} else {

				blockReports = getBlockJsonObject[pyramidActiveIndex].reports;	

			}

			// for loop - reports
			for(var i = 0; i < blockReports.length; i++) {

				var pyrmaidActivePosition = $(this).closest('.pyramid-wrapper--active').data('pyramid-position');

				var parentHierarchyLevel = $(this).data('id').charAt(0);

				switch(parentHierarchyLevel) {

					case 'A':
						iconName = 'advanced-performance-drivers';
					break;

					case 'B':
							iconName = 'focus';
					break;

					case 'C':
							iconName = 'business-development';
					break;

					case 'D':
							iconName = 'operational-efficiency';
					break;

					case 'E':
							iconName = 'compliance';
					break;

					case 'T':
						iconName = 'tab';
					break;

					default:
						iconName = 'tab';
				}

				// append html list items
				$('.modal__inner-content-multi-report-list').append(

					'<li class="modal__inner-content-multi-report-list-item">' +

					'<a href="#" class="modal__inner-content-multi-report-list-link modal-hash-link report-link report-link--single-child" data-id-origin="' + pyrmaidActivePosition + '" data-id="' + $(this).data('id') + '-' + i + '" data-minimize-id="' + $(this).data('id') + '-' + i + '-P' + pyrmaidActivePosition + '" data-hierarchy-level="' + $(this).data('id').charAt(0) + '" data-report-name="' + blockReports[i].business_name + '">' +

							'<img src="assets/components/mor/General/img/icon-'+ iconName +'@2x.png">' +

							'<span class="minimize-list__text">' +

								blockReports[i].business_name +

							'</span>' +

						'</a>' +

					'</li>'

				);

				var modalListItemSortInnerText = $('.modal__inner-content-multi-report-list').find('.modal__inner-content-multi-report-list-item').sort(sortInnerText);
				$('.modal__inner-content-multi-report-list').append(modalListItemSortInnerText);

				// add class to link if no report url
				var noReportUrl = blockReports[i].url;

				// if link has no report url add modal__inner-content-multi-report-list-link--no-report class
				if(noReportUrl === '%null%') {

					$('.modal__inner-content-multi-report-list-link[data-id="'+ $(this).data('id') + '-' + i +'"]').addClass('modal__inner-content-multi-report-list-link--no-report');

				}
				
			} // end for loop - reports

			// add block id to modal to identify mulitple report as 'parent'
			$('.modal').attr('data-id-parent', $(this).data('id'));
		
		}

		// if opening modal from a minimize list link
		if($(this).hasClass('minimize-list__link')) {

			// if minimize-list__link is not a tab link
			if(!$(this).hasClass('minimize-list__link--tab')) {

				// get minimize-id
				var minId = $(this).data('minimize-id');

				// get pryamid index
				var minPyramidIndex = minId.split('-P').pop().trim();

				// get pyramid object
				var minPyramidObject = pyramids[minPyramidIndex];

				// // get pyramid hierarchies
				var minPyramidHierarchies = minPyramidObject.hierarchies;

				// get clicked block hierarchy id
				var minPyramidHierarchyLevel = minId.charAt(0);

				// get block object
				var getBlockJsonObjectMin = getObjects(minPyramidHierarchies, 'hierarchy_id', minPyramidHierarchyLevel);

				// get clicked block array
				var minPyramidBlock = getBlockJsonObjectMin[0].blocks;

				// set minimize pyramid reports
				var minPyramidReports;

				// if single report link
				if($(this).hasClass('minimize-list__link--single')) {

					// get position of block object based on block_id
					var elementPos = minPyramidBlock.map(function(x) {return x.block_id; }).indexOf($(this).data('id'));

					// get report matching position
					minPyramidReports = minPyramidBlock[elementPos].reports;

					// set blockReportIndex to get related report url
					window.blockReportIndex = minPyramidReports[0];

				}

				// if child report link
				if($(this).hasClass('minimize-list__link--single-child')) {

					// set reports value

					var childParentBlockId = $(this).data('id').split('-')[0];                   
					var elementPos = minPyramidBlock.map(function(x) {return x.block_id; }).indexOf(childParentBlockId);
					minPyramidReports = minPyramidBlock[elementPos].reports;

					// get index from minimize-id data attribute
					var minBlockIndex = $(this).data('minimize-id').match("-(.*)-");
					var minActiveBlockIndex = minBlockIndex[1];

					// set blockReportIndex to get related report url
					window.blockReportIndex = minPyramidReports[minActiveBlockIndex];

				}

			}

			// if minimize-list__link is a tab link
			else {

				// get tab object
				var getBlockJsonObject = getObjects(tabMenuItems, 'tab_report_id', $(this).data('id'));

				// set blockReportIndex to get related report url
				window.blockReportIndex = getBlockJsonObject[0];

			}

			// call openSingleReportModal function
			openSingleReportModal(e);

		}

		// if link has report url
		if(!$(this).hasClass('pyramid__block--no-report')) {

			if(window.matchMedia('screen and (max-width: 768px)').matches) {

				if($(e.target).hasClass('report-link--multiple')) {

					// call openModalAnimation function
					openModalAnimation(e);

				}
			}

			if(window.matchMedia('screen and (min-width: 768px)').matches) {

				// call openModalAnimation function
				openModalAnimation(e);

			}

		}

		// animate fade and scale multiple report links
		tl.to($('.modal__inner-content-multi-report-list'), .5, {delay:0, opacity:1, scaleX:1, scaleY:1});

		e.preventDefault();

	}

	// close modal tasks depending on whether closing single or multiple report modal
	function closeModal(e) {

		// unlock scroll on modal close
		$('body').removeClass('no-scroll');

		$('.view-hierarchy-list-container, .pyramid-content, .pyramid-accordions-content, .footer-wrapper, #cookie-note').css('opacity', 1);

		// if close button is within a mulitple report child modal go back to 'parent' modal
		// go back to parent modal
		if($(this).hasClass('multiple-report-child')) {

			// add a hash for parent modal windows in the open modal function to include it in history and prevent parent modal auto closing
			// this hash has no other function, as designed
			setTimeout(function () {
				var parentModalTitle = $('.modal__inner-title').text();
				parentModalTitle = parentModalTitle.replace(/ /g, '-');
				location.hash = '#reports_' + parentModalTitle;
			}, 1);

			// scale modal__inner by amount depending on media query
			if(window.matchMedia('screen and (min-width: 769px)').matches) {

				// return to original size set in css
				tl.to($('.modal__inner'), .5, {delay:0, height:'70%', width:'940px', top:'15%'});

			}

			// call resetModalContents function
			resetModalContents(e);

			// get parent modal id
			var parentId = $(this).closest('.modal').attr('data-id-parent');

			// set modal title as name of parent block object
			$('.modal__inner-title').text($('.pyramid-wrapper--active .pyramid__block--'+ parentId).text());

			// show modal__inner-content-multi-report-list
			$('.modal__inner-content-multi-report-list').show();

			// get pyramid active index
			var pyramidActiveIndex = $('body').find('.pyramid-wrapper--active').data('pyramid-position');

			// returns an array of matching objects
			var getBlockJsonObject = getObjects(pyramids, 'block_id', parentId);

			// set block object data values
			var blockReports;

			// if more than one block show report at block at approriate index
			if(getBlockJsonObject.length === 1) {

				blockReports = getBlockJsonObject[0].reports;

			} else {

				blockReports = getBlockJsonObject[pyramidActiveIndex].reports;	

			}

			// add html for child reports
			for(var i = 0; i < blockReports.length; i++) {

				var pyrmaidActivePosition = $('.pyramid-wrapper--active').data('pyramid-position');

				var parentHierarchyLevel = parentId.charAt(0);

				switch(parentHierarchyLevel) {

					case 'A':
						iconName = 'advanced-performance-drivers';
					break;

					case 'B':
							iconName = 'focus';
					break;

					case 'C':
							iconName = 'business-development';
					break;

					case 'D':
							iconName = 'operational-efficiency';
					break;

					case 'E':
							iconName = 'compliance';                  
					break;

					case 'T':
						iconName = 'tab';						
					break;

					default:
						iconName = 'tab';
				}

				// append html list items on returning from child report page
				$('.modal__inner-content-multi-report-list').append(

					'<li class="modal__inner-content-multi-report-list-item">' +

					'<a href="#" class="modal__inner-content-multi-report-list-link modal-hash-link report-link report-link--single-child" data-id-origin="' + pyrmaidActivePosition + '" data-id="' + parentId + '-' + i + '" data-minimize-id="' + parentId + '-' + i + '-P' + pyrmaidActivePosition + '" data-hierarchy-level="' + parentId.charAt(0) + '" data-report-name="' + blockReports[i].business_name + '">' +

							'<img src="assets/components/mor/General/img/icon-'+ iconName +'@2x.png">' +

							blockReports[i].business_name +

						'</a>' +

					'</li>'

				);

				var modalListItemSortInnerText = $('.modal__inner-content-multi-report-list').find('.modal__inner-content-multi-report-list-item').sort(sortInnerText);
				$('.modal__inner-content-multi-report-list').append(modalListItemSortInnerText);

				// get block report url object
				var noReportUrl = blockReports[i].url;

				// if link has no report url add modal__inner-content-multi-report-list-link--no-report class
				if(noReportUrl === '%null%') {

					$('.modal__inner-content-multi-report-list-link[data-id="'+ parentId +'-'+ i +'"]').addClass('modal__inner-content-multi-report-list-link--no-report');

				}

			}

			// re-add multiple report class to modal inner
			$('.modal__inner').addClass('modal__inner--multiple-report');

			$('.modal__back-button').hide();

			// animate child report links
			tl.to($('.modal__inner-content-multi-report-list'), .5, {delay:0, opacity:1, scaleX:1, scaleY:1});
			
		}

		// close modal
		else {

			closeModalHash(e);
			
			setTimeout(function(e) {

				// call resetModalContents function
				resetModalContents(e);

			}, 1000);

			// remove data-id-source attribute
			$('.modal').removeAttr('data-id-parent');

			// call closeModalAnimation function
			closeModalAnimation(e);

		}

		e.preventDefault();

	}

	// open a single report and get and set all related data
	function openSingleReportModal(e) {

		if(window.matchMedia('screen and (max-width: 768px)').matches) {

			// open single reports on small screens
			if(!$(e.target).hasClass('report-link--multiple')) {

				window.open(blockReportIndex.url, '_blank');

				// closes modal after x time - simplist way to prevent user seeing an open modal when they return from the report in the newly opened tab
				if ($('.modal__inner').hasClass('modal__inner--multiple-report')) {

					// just clear hash					
					closeModalHash(e);

				} else {

					// close modal, includes closeModalHash function
					setTimeout(function () {
						closeModal(e);
						closeModalAnimation(e);
					}, 500);

				}

			}

		}

		else {

			// lock scroll on report open
			$('body').addClass('no-scroll');

			// remove modal inner multiple report class
			$('.modal__inner').removeClass('modal__inner--multiple-report');

			// add modal inner single report class
			$('.modal__inner').addClass('modal__inner--single-report');

			// scale modal full screen
			tl.to($('.modal__inner'), .5, {delay:0, height:'100%', width:'100%', top:'0'});

			// add block title to modal heading
			$('.modal__inner-title').text(blockReportIndex.business_name);

			// add report url to modal iframe
			// timeout required to offset choppy opening animation
			setTimeout(function() {

				$('#single-report-iframe-wrapper').show();
				$('#single-report-iframe-wrapper').append(
				
					'<iframe src="' + blockReportIndex.url + '" frameborder="0" height="100%" width="100%" id="single-report-iframe" scrolling="yes"></iframe>'
				
				);

			}, 1250);
			
			// if no vimeo id provided hide video container else show container and create vimeo player
			if(blockReportIndex.vimeo_id === '%null%') {

				// hide video elements
				$('.modal__video-button').hide();

			} else {

				// show video elements
				$('.modal__video-button').show();

				// add vimeo source to iframe
				$('.modal__video-container-content-iframe').attr('src', 'https://player.vimeo.com/video/' + blockReportIndex.vimeo_id + '');

				// create global variable to store vimeo player
				window.vimeoPlayer = new Vimeo.Player($('.modal__video-container-content-iframe'));

				// vimeo json object
				var vimeoJsonObjectLink = 'https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/' + blockReportIndex.vimeo_id; 

				// get vimeo json object
				$.getJSON(vimeoJsonObjectLink).done(function(vimeoJson) {

					// add vimeo title
					$('.modal__video-container-content-title').text(vimeoJson.title);

					// add vimeo description
					$('.modal__video-container-content-description-inner').text( vimeoJson.description );


				}).fail(function(jqxhr, textStatus, error) {

						var err = textStatus + ', ' + error;

						console.warn( 'Request Failed: ' + err );

				});

			}

		}

		e.preventDefault();

	}

	// open a report which is one of multiple child reports in block json object
	function openModalChildReport(e) {

		// if does have a report
		if(!$(this).hasClass('modal__inner-content-multi-report-list-link--no-report')) {

			var pyramidActiveIndex;

			if ($(this).hasClass('most-used-list__link') || $(this).hasClass('nav-sidebar-most-used-sub-list__link') || $(this).hasClass('search-result-list__link') || $(this).hasClass('mobile-search-result-list__link')) {

				pyramidActiveIndex = $(this).data('id-origin');

			} else {

				// get active pryramid index in pyramids array
				pyramidActiveIndex = $('body').find('.pyramid-wrapper--active').data('pyramid-position');

			}

			// report object iteration
			var reportObjectIteration = $(this).data('id').split('-').pop().trim();

			// block id index
			var blockIdIndex = $(this).data('id').split('-')[0];

			// get parent block object of report object iteration
			var getBlockJsonObject = getObjects(pyramids, 'block_id', blockIdIndex);

			// report objects of parent block
			var reportObject;

			// if more than one block show report at block at approriate index
			if(getBlockJsonObject.length === 1) {

				reportObject = getBlockJsonObject[0].reports;

			} else {

				reportObject = getBlockJsonObject[pyramidActiveIndex].reports;

			}
			
			// global variable to be used with openSingleReportModal function
			window.blockReportIndex = reportObject[reportObjectIteration];

			if(window.matchMedia('screen and (min-width: 768px)').matches) {

				// if opening from mobile nav change height and width to grow modal inner to full size else grow to predfined css size
				if ($(this).closest('nav').hasClass('nav__sidebar__content') || $(this).closest('li').hasClass('search-result-list__item')) {

					$('.modal__inner').css({'height':'100%', 'width':'100%', 'top':'0'});

				} else {

					tl.to($('.modal__inner'), .5, {delay:0, height:'100%', width:'100%', top:'0'});

				}

			}

			// call openSingleReportModal function
			openSingleReportModal(e);

			if(window.matchMedia('screen and (min-width: 768px)').matches) {

				// empty report list to prevent doubling of content on re-opening modal
				$('.modal__inner-content-multi-report-list').hide();
				tl.to($('.modal__inner-content-multi-report-list'), .5, {delay:.5, opacity:0, scaleX:.85, scaleY:.85});

			}
			
		} else {

			// child block has no report url

			// do nothing

		}

		e.preventDefault();

	}

	// animations for opening of modal
	function openModalAnimation(e) {

		// show modal
		$('.modal').fadeIn(350);
			
		// fade and scale modal__inner
		tl.to($('.modal__inner'), .5, {delay:.35, opacity:1, scaleX:1, scaleY:1});

		e.preventDefault();

	}

	// animations for opening of video modal
	function openSubscriptionModal(e, iframeUrl, urlReportName) {

		if (window.matchMedia('screen and (max-width: 768px)').matches) {

			// open report in a new tab if on mobile
			window.open(iframeUrl, '_blank');

			closeModalHash(e);

		}

		else {

			$('body').addClass('no-scroll');

			// show modal
			$('.subscription-link-modal').fadeIn(350);

			//
			$('.subscription-link-modal__inner-title').text(urlReportName);

			//
			$('#subscription-link-modal-iframe-wrapper').append(

				'<iframe src="' + iframeUrl + '" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'

			);
			// $('.subscription-link-modal__inner-content iframe').attr('src', iframeUrl);

			// fade and scale modal__inner
			tl.to($('.subscription-link-modal__inner'), .5, { delay: .35, opacity: 1, scaleX: 1, scaleY: 1 });

		}

		e.preventDefault();

	}

	// animations for closing of modal
	function closeSubscriptionModal(e) {

		// remove body scrolling lock after timeout
		setTimeout(function () {

			$('body').removeClass('no-scroll');

		}, 1000);

		// fadeOut modal after delay
		$('.subscription-link-modal').delay(550).fadeOut(350);

		// reset scale and opacity
		tl.to($('.subscription-link-modal__inner'), .5, { delay: 0, opacity: 0, scaleX: .85, scaleY: .85 });

		//
		$('.subscription-link-modal__inner-title').text('');

		//
		$('#subscription-link-modal-iframe-wrapper iframe').remove();

		e.preventDefault();

		closeModalHash(e);

	}

	// animations for opening of video modal
	function openVideoModal(e) {

		$('body').addClass('no-scroll');

		// show modal
		$('.video-modal').fadeIn(350);

		//
		$('.video-modal__inner-title').text($(e.target).text());

		// fade and scale modal__inner
		tl.to($('.video-modal__inner'), .5, { delay: .35, opacity: 1, scaleX: 1, scaleY: 1 });

		e.preventDefault();

	}

	// animations for closing of modal
	function closeVideoModal(e) {

		// remove body scrolling lock after timeout
		setTimeout(function () {

			$('body').removeClass('no-scroll');

		}, 1000);

		// fadeOut modal after delay
		$('.video-modal').delay(550).fadeOut(350);

		// reset scale and opacity
		tl.to($('.video-modal__inner'), .5, { delay: 0, opacity: 0, scaleX: .85, scaleY: .85 });

		//
		$('.video-modal__inner-title').text('');

		$('.video-modal__inner-content iframe').attr('src', '');

		setTimeout(function () {

			$('.video-modal__inner').css({ 'height': '70%', 'width': '940px', 'top': '15%' });

		}, 500);

		e.preventDefault();

	}

	// open modal video container		
	function openVideoContainer(e) {

		// change display at this stage to avoid accidentally tabbing into video container before it is opened
		$('.modal__video-container').css('display', 'block');

		// position description below title and video
		var modalVideoContainerContentHeader = $('.modal__video-container-content-header').outerHeight(true);
		$('.modal__video-container-content-description-outer').css('top', modalVideoContainerContentHeader + 15);

		// animate video container open by px or % depending on media query
		if (window.matchMedia('screen and (min-width: 769px)').matches) {

			tl.to($('.modal__video-container'), .5, {x:'-320px'});

		} else {

			tl.to($('.modal__video-container'), .5, {x:'-100%'});

		}

		e.preventDefault();

	}

	// minimize modal video container
	function minimizeVideoContainer(e) {

		// animate video container closed by px or % depending on media query
		if (window.matchMedia('screen and (min-width: 769px)').matches) {

			tl.to($('.modal__video-container'), .5, {x:'0px'});

		} else {

			tl.to($('.modal__video-container'), .5, {x:'0%'});

		}

		// set display to none after amount of time it takes to animate video container out of view
		setTimeout(function() {

			$('.modal__video-container').css('display', 'none');

		},500);

		e.preventDefault();

	}

	// close modal video container and stop video
	function closeVideoContainer(e) {

		// animate video container open by px or % depending on media query
		if (window.matchMedia('screen and (min-width: 769px)').matches) {

			tl.to($('.modal__video-container'), .5, {x:'0px'});

		} else {

			tl.to($('.modal__video-container'), .5, {x:'0%'});

		}

		// stop vimeo player
		vimeoPlayer.unload();

		e.preventDefault();
		
	}

	// scroll to toolbar on first time item is added to toolbar to make user aware of feature
	/* function minimizeToolbarFirstTimeGuide(e) {

		// if not called on multiple modal
		if(!$('.modal__close-button').hasClass('modal__close-button--multiple')) {

			if (!localStorage.getItem('viewed')) {
			
				// scroll to minimize-section after timeout
				setTimeout(function() {

					$('html, body').animate({scrollTop: $('.minimize-section').offset().top}, 750);

					tl.to($('.minimize-list__item img'), .3, {delay:1.5, scaleX:1.1, scaleY:1.1})
						.to($('.minimize-list__item img'), .3, {delay:0, scaleX:1, scaleY:1});

				}, 1000);

				localStorage.setItem('viewed','yes');

			};

		}

	} */

	// reset contents of modal
	function resetModalContents(e) {

		// animate video container closed by px or % depending on media query
		if (window.matchMedia('screen and (min-width: 769px)').matches) {

			tl.to($('.modal__video-container'), .5, {x:'0px'});

		} else {

			tl.to($('.modal__video-container'), .5, {x:'0%'});

		}

		// reset modal inner classes
		$('.modal .modal__inner').attr('class', 'modal__inner');

		// if modal__close-button has modal__close-button--multiple class remove it
		if($('.modal__close-button').hasClass('modal__close-button--multiple')) {

			$('.modal__close-button').removeClass('modal__close-button--multiple');	

		}

		// reset modal heading
		$('.modal__inner-title').empty();

		// hide video button 
		$('.modal__video-button').hide();

		// hide back button
		$('.modal__back-button').hide();

		// reset report url modal iframe
		$('#single-report-iframe-wrapper').hide();
		$('#single-report-iframe-wrapper #single-report-iframe').remove();

		// reset vimeo heading
		$('.modal__video-container-content-title').empty();

		// reset vimeo description
		$('.modal__video-container-content-description-inner').empty();

		// reset vimeo iframe
		$('.modal__video-container-content-iframe').attr('src', '');

		// empty report list to prevent doubling of content on re-opening modal
		$('.modal__inner-content-multi-report-list').empty();

	}

	// animations for closing of modal
	function closeModalAnimation(e) {

		// remove body scrolling lock after timeout
		setTimeout(function() {

			$('body').removeClass('no-scroll');

		}, 1000);

		// fadeOut modal after delay
		$('.modal').delay(550).fadeOut(350);

		// scale modal__inner by amount depending on media query
		if(window.matchMedia('screen and (max-width: 768px)').matches) {
			
			// reset scale and opacity
			tl.to($('.modal__inner'), .5, {delay:0, opacity:0, scaleX:.75, scaleY:.75});

		}

		// scale modal__inner by amount depending on media query
		if(window.matchMedia('screen and (min-width: 769px)').matches) {
			
			// reset scale and opacity
			tl.to($('.modal__inner'), .5, {delay:0, opacity:0, scaleX:.85, scaleY:.85});

			setTimeout(function() {

				$('.modal__inner').css({'height':'70%', 'width':'940px', 'top':'15%'});
			
			}, 500);
			
		}

		// reset scale and opacity
		tl.to($('.modal__inner-content-multi-report-list'), .5, {delay:0, opacity:0, scaleX:.85, scaleY:.85});

		// call minimizeToolbarFirstTimeGuide function
		// minimizeToolbarFirstTimeGuide(e);

		e.preventDefault();

	}

	// on clicking outside modal__inner call closeModal function
	$('.modal').on('click', function(e) {	

		// detect if target clicked is outside modal__inner, if true call closeModal()
		if($('.modal__inner').has(e.target).length == 0 && !$('.modal__inner').is(e.target)) {

			closeModal(e);

		}

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- BIND FUNCTIONS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// desktop pyramid functions
	$('.pyramid__block.report-link:not(.pyramid__block--no-report)').on('click', openModal);
	$('.report-link:not(.pyramid__block--no-report)').on('click', addToMinimizedList);

	// mobile pyramid functions
	$('.pyramid-wrapper').on('click', '.pyramid-mobile-accordion__content-link', openModal);
	$('.pyramid-wrapper').on('click', '.pyramid-mobile-accordion__content-link', addToMinimizedList);

	// mobile projects / most used accordion functions
	$('.pyramid-accordions-content').on('click', '.report-link', openModal);
	$('.pyramid-accordions-content').on('click', '.report-link--single-child', openModalChildReport);
	$('.pyramid-accordions-content').on('click', '.tab-link', openModal);
	$('.pyramid-accordions-content').on('click', '.tab-link', addToMinimizedList);

	// bind openModalChildReport function to link click events
	$('.modal__inner-content-multi-report-list').on('click touchend', '.modal__inner-content-multi-report-list-link', openModalChildReport);

	// minimize list open modal functions
	$('.minimize-list').on('click touchend', '.minimize-list__link--single', openModal);
	$('.minimize-list').on('click touchend', '.minimize-list__link--single-child', openModal);
	$('.minimize-list').on('click touchend', '.minimize-list__link--tab', openModal);

	// add to minimize list functions
	$('.modal__inner-content-multi-report-list').on('click touchend', '.modal__inner-content-multi-report-list-link:not(.modal__inner-content-multi-report-list-link--no-report)', addToMinimizedList);

	// bind video functions to link click events
	$('.modal__video-button').on('click', openVideoContainer);

	$('.modal__video-container-control--minimize').on('click', minimizeVideoContainer);

	$('.modal__video-container-control--close').on('click', closeVideoContainer);

	// on click event for dynamic modal__inner-content-multi-report-list-link add class to close button to set up go back functionality
	$('.modal__inner-content-multi-report-list').on('click touchend', '.modal__inner-content-multi-report-list-link', function() {

		if(!$(this).hasClass('modal__inner-content-multi-report-list-link--no-report')) {

			if(window.matchMedia('screen and (min-width: 768px)').matches) {

				$('.multiple-report-child').show();
					
			}

		}

	});

	// bind closeModal function to link click events
	$('.modal__close-button').on('click', closeModal);
	$('.modal__back-button').on('click', closeModal);

	// disable links with 'no report' class
	$('.pyramid__block--no-report').on('click', function(e) {

		// do nothing

		e.preventDefault();

	});

	// nav sidebar functions
	$('.nav__sidebar__content').on('click', '.report-link--single', openModal);

	$('.nav__sidebar__content').on('click', '.report-link--single-child', openModal);
	$('.nav__sidebar__content').on('click', '.report-link--single-child', openModalChildReport);

	$('.nav__sidebar__content').on('click', '.tab-link', openModal);
	$('.nav__sidebar__content').on('click', '.tab-link', addToMinimizedList);

	$('.subscription-link-modal__close-button').on('click', closeSubscriptionModal);
	$('.video-modal__close-button').on('click', closeVideoModal);	

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- MINIMIZE TOOLBAR ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// create minimizeArray
	var minimizeArray = [];

	// define minimize-list owl carousel
	var minimizeCarousel = $('.minimize-list');

	// add to minimize list functionality
	function addToMinimizedList(e) {

		// if clicked element does not has multiple class
		if(!$(this).hasClass('report-link--multiple')) {

			// if clicked element does have no report class
			if(!$(this).hasClass('pyramid__block--no-report')) {

				// remove owl-carousel--empty class which creates style for when carousel is empty
				minimizeCarousel.removeClass('owl-carousel--empty');
			
				var pyramidBlockId = $(this).data('id'),
					pyramidBlockMinimizeId = $(this).data('minimize-id');
					
				var minimizeObject = {

					"id": pyramidBlockId,
					
					"minimizeId": pyramidBlockMinimizeId

				}

				// check if minimizeObject already exists in array
				objectLocation = false;

				for(var i = 0; i < minimizeArray.length; i++) {

					if(minimizeArray[i]['minimizeId'] === pyramidBlockMinimizeId) {

						objectLocation = i;

					}

				}

				// if not in array add to top of array
				if(objectLocation === false) {

					minimizeArray.unshift(minimizeObject);

				}

				// if not tab link
				if(!$(this).hasClass('tab-link')) {

					// get pyramid wrapper id of clicked block
					var minPyramidId = $(this).data('minimize-id').split('-P').pop().trim()

					// active pyramid
					var minActivePyramid = pyramids[minPyramidId];

					// get hierarchy id from minimize-id
					var minHierarchyId = $(this).data('minimize-id').charAt(0);

					// get object of hierarchy of clicked block
					var minHierarchyObject = getObjects(minActivePyramid, 'hierarchy_id', minHierarchyId);

					// get blocks array of hierarchy
					var minBlocksArray = minHierarchyObject[0].blocks;

					// get block id from minimize-id
					var minActiveBlockId = $(this).data('minimize-id').split('-')[0];

					// get object of clicked block
					var minActiveBlockObject = getObjects(minBlocksArray, 'block_id', minActiveBlockId);

					// get reports array from clicked block
					var minReportsArray = minActiveBlockObject[0].reports;

					// create variables
					var reportBusinessName,
							reportUrl,
							reportVimeoId;

					var minimizeListLinkClass;

					if($(this).hasClass('report-link--single')) {

						reportBusinessName = minReportsArray[0].business_name;
						reportUrl = minReportsArray[0].url;
						reportVimeoId = minReportsArray[0].vimeo_id;

						minimizeListLinkClass = 'minimize-list__link--single';

					}

					if($(this).hasClass('report-link--single-child')) {

						// get index of clicked block from minimize-id
						var minBlockIndex = $(this).data('minimize-id').match("-(.*)-"),
								minActiveBlockIndex = minBlockIndex[1];
											
						reportBusinessName = minReportsArray[minActiveBlockIndex].business_name;
						reportUrl = minReportsArray[minActiveBlockIndex].url;
						reportVimeoId = minReportsArray[minActiveBlockIndex].vimeo_id;

						minimizeListLinkClass = 'minimize-list__link--single-child';

					}

				}

				// if already in array
				if(objectLocation !== false) {

					// get object of existing block
					var getBlockJsonObject = getObjects(minimizeArray, 'minimizeId', $(this).data('minimizeId'));

					// get index of existing blcok in minimizeArray
					var index = $.inArray(
						JSON.stringify(getBlockJsonObject).replace(/]|[[]/g, ''),
						$.map(minimizeArray, JSON.stringify)
					);

					// remove owl item at index of existing object
					minimizeCarousel.trigger('remove.owl.carousel', index);

					// move to top of array
					minimizeArray.splice(objectLocation, 1);
					minimizeArray.unshift(minimizeObject);

				}

				// set up variables for if tab or not
				var minimizeHTMLLinkClass,
						minimizeHTMLHierarchyLevel,
						minimizeHTMLName,
						minimizeHTMLIconName;

				// if does not have tab-link assign values
				if(!$(this).hasClass('tab-link')) {

					minimizeHTMLLinkClass = minimizeListLinkClass;

					minimizeHTMLHierarchyLevel = $(this).data('id').charAt(0);

					minimizeHTMLName = reportBusinessName;

				}

				// else assign these values
				else {

					minimizeHTMLLinkClass = 'minimize-list__link--tab';

					minimizeHTMLHierarchyLevel = 'T';

					minimizeHTMLName = $(this).text();

				}

				// assign matching icon name to hierarchy level
				switch(minimizeHTMLHierarchyLevel) {

					case 'A':
						minimizeHTMLIconName = 'advanced-performance-drivers';
					break;

					case 'B':
							minimizeHTMLIconName = 'focus';
					break;

					case 'C':
							minimizeHTMLIconName = 'business-development';
					break;

					case 'D':
							minimizeHTMLIconName = 'operational-efficiency';
					break;

					case 'E':
							minimizeHTMLIconName = 'compliance';
					break;

					case 'T':
						minimizeHTMLIconName = 'tab';
					break;

					default:
						minimizeHTMLIconName = 'tab';

				}

				// add item to owl carousel
				minimizeCarousel.owlCarousel(
						
					'add',
					
					'<li class="minimize-list__item" data-id="'+ $(this).data('minimize-id') +'">'+

						'<div class="minimize-list__item-inner">'+

							'<a href="#" class="modal-hash-link minimize-list__link' + ' ' + minimizeHTMLLinkClass + '" data-id-origin="' + $(this).data('minimize-id').slice(-1) + '" data-id="' + $(this).data('id') + '" data-minimize-id="' + $(this).data('minimize-id') + '" data-hierarchy-level="' + minimizeHTMLHierarchyLevel + '" data-report-name="' + minimizeHTMLName + '">'+

								'<div class="minimize-list__img"><img src="assets/components/mor/General/img/icon-'+ minimizeHTMLIconName +'@2x.png"></div>' +

								'<span class="minimize-list__text">' +

									minimizeHTMLName +

								'</span>' +

							'</a>' +

						'</div>'+

					'</li>', // data

					0 // position

				).trigger('refresh.owl.carousel');

				// animate minimize-list__item-inner
				tl.to($('.minimize-list__item-inner'), .3, {delay:0, opacity:1, scaleX:1, scaleY:1});

				// call resizeOwlControls function
				resizeOwlControls();

				// scroll to start of carousel
				minimizeCarousel.trigger('to.owl.carousel', [0, 750]);

			}

		}		

		e.preventDefault();

	}

	// listen for hash change to call function as user navigates through browser history
	window.addEventListener("hashchange", openModalFromUrlHash);

	function openModalFromUrlHash() {

		var pageUrl = $(location).attr('href');

		// if is not parent modal hash
		if (!pageUrl.includes('#reports_')) {

			if (pageUrl.indexOf('#') >= 0) {

				var testRegex = new RegExp('(\/)(site)(\/)(.*)(\/)(views)(\/)(.*)');

				// exclude regex from hash check, interferes with pass through of subscription links
				if (!testRegex.test(pageUrl)) {

					// http://success.realworldanalytics.com/test/index.php#site_AutoDemo_0_C6_6--10-Budget-Planning_Sales

					var pageUrlHashString = pageUrl.substr(pageUrl.indexOf('#'));

					// without site name in url
					// substring between first '#' and before first '_'
					// var urlPyramidActiveIndex = pageUrlHashString.substr(
					// 	pageUrlHashString.indexOf('#') + 1,
					// 	pageUrlHashString.indexOf('_') - 1
					// );

					// new pattern to work around addition of site name
					var urlPyramidActiveIndexSubstringIndexStart = nthIndex(pageUrlHashString, '_', 2) + 1;
					var urlPyramidActiveIndexSubstringIndexEnd = pageUrlHashString.substr(urlPyramidActiveIndexSubstringIndexStart);
					var urlPyramidActiveIndex = urlPyramidActiveIndexSubstringIndexEnd.split('_')[0];

					// without site name in url
					// get block id
					// var pageUrlHashStringSeparatorIndex = pageUrlHashString.indexOf('_') + 1;
					// var urlBlockId = pageUrlHashString.substr(pageUrlHashStringSeparatorIndex);
					// urlBlockId = urlBlockId.split('_')[0];

					// new pattern to work around addition of site name
					var urlBlockIdSubstringIndexStart = nthIndex(pageUrlHashString, '_', 3) + 1;
					var urlBlockIdSubstringIndexEnd = pageUrlHashString.substr(urlBlockIdSubstringIndexStart);
					var urlBlockId = urlBlockIdSubstringIndexEnd.split('_')[0];

					//
					var urlMinimizeId = urlBlockId + '-P' + urlPyramidActiveIndex;

					// get report name
					// without site name in url
					// var urlReportNameSubstringIndex = nthIndex(pageUrlHashString, '_', 2) + 1;
					// var urlReportName = pageUrlHashString.substr(urlReportNameSubstringIndex);
					// urlReportName = urlReportName.split('_')[0];
					// urlReportName = urlReportName.replace('--', '.').replace(/-/g, ' ');

					// new pattern to work around addition of site name
					var urlReportNameSubstringIndexStart = nthIndex(pageUrlHashString, '_', 4) + 1;
					var urlReportNameSubstringIndexEnd = pageUrlHashString.substr(urlReportNameSubstringIndexStart);
					var urlReportName = urlReportNameSubstringIndexEnd.split('_')[0];
					urlReportName = urlReportName.replace('--', '.').replace(/-/g, ' ');

					// leave commented out
					// causing reports to be added twice to recentlyViewed list
					/* // add to minimized list on load - start

					// remove owl-carousel--empty class which creates style for when carousel is empty
					minimizeCarousel.removeClass('owl-carousel--empty');

					var pyramidBlockId = urlBlockId,
						pyramidBlockMinimizeId = urlMinimizeId,
						pyramdidBlockOrigin = urlMinimizeId.slice(-1);

					var minimizeObject = {

						"id": pyramidBlockId,

						"minimizeId": pyramidBlockMinimizeId

					}

					// directly add to top of Array, this function is run on page load so no need to check if this entry already exists in the array
					minimizeArray.unshift(minimizeObject);

					var minimizeHTMLHierarchyLevel = pyramidBlockId.charAt(0);

					// assign matching icon name to hierarchy level
					switch (minimizeHTMLHierarchyLevel) {

						case 'A':
							minimizeHTMLIconName = 'advanced-performance-drivers';
							break;

						case 'B':
							minimizeHTMLIconName = 'focus';
							break;

						case 'C':
							minimizeHTMLIconName = 'business-development';
							break;

						case 'D':
							minimizeHTMLIconName = 'operational-efficiency';
							break;

						case 'E':
							minimizeHTMLIconName = 'compliance';
							break;

						case 'T':
							minimizeHTMLIconName = 'tab';
							break;

						default:
							minimizeHTMLIconName = 'tab';

					}

					var minimizeListLinkClass;

					// '-' in block id indicates child report
					if (urlBlockId.includes('-')) {

						// single child report
						minimizeListLinkClass = 'minimize-list__link--single-child';

					} else {

						// single report
						minimizeListLinkClass = 'minimize-list__link--single';

					}

					// add item to owl carousel
					minimizeCarousel.owlCarousel(

						'add',

						'<li class="minimize-list__item" data-id="' + pyramidBlockMinimizeId + '">' +

						'<div class="minimize-list__item-inner">' +

						'<a href="#" class="modal-hash-link minimize-list__link ' + minimizeListLinkClass + '" data-id-origin="' + pyramdidBlockOrigin + '" data-id="' + pyramidBlockId + '" data-minimize-id="' + pyramidBlockMinimizeId + '" data-hierarchy-level="' + minimizeHTMLHierarchyLevel + '" data-report-name="' + urlReportName + '">' +

						'<div class="minimize-list__img"><img src="assets/components/mor/General/img/icon-' + minimizeHTMLIconName + '@2x.png"></div>' +

						'<span class="minimize-list__text">' +

						urlReportName +

						'</span>' +

						'</a>' +

						'</div>' +

						'</li>', // data

						0 // position

					).trigger('refresh.owl.carousel');

					// animate minimize-list__item-inner
					tl.to($('.minimize-list__item-inner'), .3, { delay: 0, opacity: 1, scaleX: 1, scaleY: 1 });

					// call resizeOwlControls function
					resizeOwlControls();

					// scroll to start of carousel
					minimizeCarousel.trigger('to.owl.carousel', [0, 750]);

					// add to minimized list on load - end */

					// returns an array of matching objects
					var getBlockJsonObject;

					// set block object data values
					var blockReports;

					// '-' in block id indicates child report
					if (urlBlockId.includes('-')) {

						// single child report
						var urlChildBlockId = urlBlockId.substr(0, urlBlockId.indexOf('-'));
						getBlockJsonObject = getObjects(pyramids, 'block_id', urlChildBlockId);

						var urlReportObject = getBlockJsonObject[urlPyramidActiveIndex].reports;

						// global variable to be used with open modal from hash function below
						var urlReportPositionIndex = urlBlockId.indexOf('-') + 1;
						var urlReportPosition = urlBlockId.substring(urlReportPositionIndex);
						window.blockReportIndex = urlReportObject[urlReportPosition];

					} else {

						// single report
						getBlockJsonObject = getObjects(pyramids, 'block_id', urlBlockId);

						blockReports = getBlockJsonObject[urlPyramidActiveIndex].reports;

						// set block report index as global variable - single report
						window.blockReportIndex = blockReports[0];

					}

					if (window.matchMedia('screen and (max-width: 768px)').matches) {

						// open report in a new tab if on mobile
						window.open(blockReportIndex.url, '_blank');

						closeModalHash();

					} else {

						$('.view-hierarchy-list-container, .pyramid-content, .pyramid-accordions-content, .footer-wrapper, #cookie-note').css('opacity', 0);

						// lock scroll on report open
						$('body').addClass('no-scroll');

						// remove modal inner multiple report class
						$('.modal__inner').removeClass('modal__inner--multiple-report');

						// // add modal inner single report class
						$('.modal__inner').addClass('modal__inner--single-report');

						// scale modal full screen
						tl.to($('.modal__inner'), .5, { delay: 0, height: '100%', width: '100%', top: '0' });

						// add block title to modal heading
						$('.modal__inner-title').text(blockReportIndex.business_name);

						// add report url to modal iframe
						// timeout required to offset choppy opening animation
						setTimeout(function () {

							$('#single-report-iframe-wrapper').show();
							$('#single-report-iframe-wrapper').append(

								'<iframe src="' + blockReportIndex.url + '" frameborder="0" height="100%" width="100%" id="single-report-iframe" scrolling="yes"></iframe>'

							);

						}, 1250);

						// if no vimeo id provided hide video container else show container and create vimeo player
						if (blockReportIndex.vimeo_id === '%null%') {

							// hide video elements
							$('.modal__video-button').hide();

						} else {

							// show video elements
							$('.modal__video-button').show();

							// add vimeo source to iframe
							$('.modal__video-container-content-iframe').attr('src', 'https://player.vimeo.com/video/' + blockReportIndex.vimeo_id + '');

							// create global variable to store vimeo player
							window.vimeoPlayer = new Vimeo.Player($('.modal__video-container-content-iframe'));

							// vimeo json object
							var vimeoJsonObjectLink = 'https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/' + blockReportIndex.vimeo_id;

							// get vimeo json object
							$.getJSON(vimeoJsonObjectLink).done(function (vimeoJson) {

								// add vimeo title
								$('.modal__video-container-content-title').text(vimeoJson.title);

								// add vimeo description
								$('.modal__video-container-content-description-inner').text(vimeoJson.description);


							}).fail(function (jqxhr, textStatus, error) {

								var err = textStatus + ', ' + error;

								console.warn('Request Failed: ' + err);

							});

						}

						openModalAnimation();

					}

				}

			}

		}

	} // end openModalFromUrlHash

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- RESIZE OWL CONTROLS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// resize owl control functions
	function resizeOwlControls() {

		// change itemNumber depending on media query
		var itemNumber = 2;

		if (window.matchMedia('screen and (min-width: 480px)').matches) {

			itemNumber = 4;

		}

		if (window.matchMedia('screen and (min-width: 992px)').matches) {

			itemNumber = 6;

		}

		// if owl item lenght > owl carousel items number show prev / next controls
		if($('.minimize-list__item').length > itemNumber) {

			// if on mobile view
			if (window.matchMedia('screen and (max-width: 768px)').matches) {

				// change margin
				$('.minimize-list').css('margin', '60px 0 0 0');

			}

			// show next control after timeout
			setTimeout(function() {

				$('.minimize-list-control--next').fadeIn();

			}, 350); // make timeout time longer than transition time on minimize-list

			
			// show prev control is has active class
			if($('.minimize-list-control--prev').hasClass('minimize-list-control--prev-active')) {

				$('.minimize-list-control--prev').fadeIn();

			}

		}

		else {

			// hide controls				
			$('.minimize-list-control--prev').removeClass('minimize-list-control--prev-active');
			$('.minimize-list-control--next').hide();

		}

	}

	// on window resize call resizeOwlControls function
	var resizeId;

	$(window).resize(function() {

		clearTimeout(resizeId);

		resizeId = setTimeout(resizeOwlControls, 100);

	});
	
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- OWL CAROUSEL (MINIMIZE LIST) ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// initialize owl carousel
	minimizeCarousel.owlCarousel({
		loop: false,
		responsive : {
			0 : {
				items: 2
			},
			480 : {
				items : 3
			},
			768 : {
				items: 4
			},
			992 : {
				items: 6
			}
		}
	});

	// show next slide on minimize-list-prev click event
	$('.minimize-list-control--next').on('click', function(e) {

		minimizeCarousel.trigger('next.owl.carousel');

		$('.minimize-list-control--prev').addClass('minimize-list-control--prev-active');

		e.preventDefault();

	});

	// show previous slide on minimize-list-prev click event
	$('.minimize-list-control--prev').on('click', function(e) {

		minimizeCarousel.trigger('prev.owl.carousel');

		e.preventDefault();

	});

	// animate prev arrow
	$('.minimize-list-control--prev').on('mouseenter', function(e) {

		tl.to($(this), .5, {x:'-3px'});

	});

	$('.minimize-list-control--prev').on('mouseleave', function(e) {

		tl.to($(this), .5, {x:'0'});

	});

	// animate next arrow
	$('.minimize-list-control--next').on('mouseenter', function(e) {

		tl.to($(this), .5, {x:'3px'});

	});

	$('.minimize-list-control--next').on('mouseleave', function(e) {

		tl.to($(this), .5, {x:'0'});

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- MISC FUNCTIONS ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// get nth index of a substring in a string, args: str, substring, occurance
	function nthIndex(str, pat, n) {
		var L = str.length, i = -1;
		while (n-- && i++ < L) {
			i = str.indexOf(pat, i);
			if (i < 0) break;
		}
		return i;
	}

	// get object matching argument
	function getObjects(obj, key, val) {

		var objects = [];

		for(var i in obj) {

			if (!obj.hasOwnProperty(i)) continue;

			if (typeof obj[i] == 'object') {

				objects = objects.concat(getObjects(obj[i], key, val));

			} else if (i == key && obj[key] == val) {

				objects.push(obj);

			}

		}

		return objects;

	}

	// sort by classname (ascending)
	function sortAsc(a, b) {

		return (b.className < a.className) ? 1 : (b.className > a.className) ? -1 : 0;	

	}

	// sort by classname (descending)
	function sortDesc(a, b) {

		return (b.className > a.className) ? 1 : (b.className > a.className) ? -1 : 0;	

	}

	// sort by innerHTML conent (ascending)
	function sortInnerText(a, b) {

			return (b.innerText < a.innerText) ? 1 : (b.innerText > a.innerText) ? -1 : 0; 

	}

	// sort by data attribute "data-id-sort-order" conent (ascending)
	function sortDataIdOrder(a, b) {

		return ($(b).data('id-sort-order') < $(a).data('id-sort-order')) ? 1 : ($(b).data('id-sort-order') > $(a).data('id-sort-order')) ? -1 : 0;

	}

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- COOKIE NOTICE ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	
	// standard cookie notice script used on many MOR sites

	checkCookie();

	function checkCookie() {

		var consent = getCookie("cookies_consent");

		if (consent == null || consent == "" || consent == undefined) {

			$('#cookie-note').show();

		}

	}

	function setCookie(c_name,value,exdays) {

		var exdate = new Date();

		exdate.setDate(exdate.getDate() + exdays);

		var c_value = escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());

		document.cookie = c_name + "=" + c_value + ";path=/";

		$('#cookie-note').slideUp('slow');

	}

	function getCookie(c_name) {
		var i,x,y,ARRcookies=document.cookie.split(";");

		for(i=0; i < ARRcookies.length; i++) {

			x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));

			y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);

			x=x.replace(/^\s+|\s+$/g,"");

			if (x==c_name) {

				return unescape(y);

			}

		}

	}

	$('#cookie-accept').click(function(e) {

		setCookie("cookies_consent", 1, 30);

		e.preventDefault();

	});

	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------
	// --- LINK RULES ---
	// -------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------------------------------------------------------------------------

	// forces pdf links to open in new tab
	$('a[href$=".pdf"]').attr('target', '_blank');

	// force external links that do not have the site base url to open in a new tab
	var baseUrl = document.location.origin;
	$('a[href^="http://"], a[href^="https://"]').not('a[href^="'+baseUrl+'"]').attr('target','_blank');

	// adds noopener noreferrer to any link with target="_blank" to avoid the window.opener phising vulnerablity
	$('a[target="_blank"]').attr('rel', 'noopener noreferrer');

												// Steven Giggins' code start
												pyramidReady();
												// openModalFromUrlHash() called after pyramid ready as function needs to wait for elements to be created
												openModalFromUrlHash();
											}).then(importVideosSheet);
										}); // end portal control from Tableau
							});

					} catch(error) {
							alert(error.get_message());
					}
					};

					vizURL = 'https://' + url + '/trusted/' + token + '/t/' + site + '/views/PortalControl/PortalControl';

					if (typeof tableau !== 'undefined') {
						viz = new tableau.Viz(vizDiv, vizURL, options);
					}
				}) // end document ready
		}) // end get token
		// Steven Giggins' code end

  } // end if not outdated browser check

	// outdated browser <= IE9
	else {	

		// -------------------------------------------------------------------------------------------------------------------
		// -------------------------------------------------------------------------------------------------------------------
		// --- OUTDATED BROWSER WARNING ---
		// -------------------------------------------------------------------------------------------------------------------
		// -------------------------------------------------------------------------------------------------------------------	

		// hide page loading indicator
		$('#page-loading-indicator').hide();

		// show outdated browser warning
		$('#outdated-browser-warning').show();

	}

});